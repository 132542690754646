import MarkdownIt from 'markdown-it'

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        /**
        * Parses the provided string using the Markdown parser
        */
        $md(string: string): string
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    const parser = new MarkdownIt()
    const tagRegex = /\[\[\/?([A-Za-z0-9-_]+)]]/g

    function renderMarkdown(content: string, arg: string | undefined) {
        if (!content) {
            return ''
        }
        if (!arg || arg.length === 0) {
            content = content.replaceAll(tagRegex, '')
        } else {
            const matches = [...content.matchAll(tagRegex)]
            const matchIdx = matches.findIndex((val) => val.length > 0 && val[1] === arg)
            if (matches.length > matchIdx + 1 && matches[matchIdx + 1][0] === `[[/${arg}]]`) {
                const start = matches[matchIdx].index || 0
                content = content.substring(start + 4 + arg.length, matches[matchIdx + 1].index)
            }
        }
        const tempEl = document.createElement('div')
        tempEl.textContent = content
        return parser.render(tempEl.innerHTML)
    }

    nuxtApp.vueApp.directive('md', {
        mounted(el, binding) {
            el.innerHTML = renderMarkdown(binding.value as string, binding.arg)
        },

        updated(el, binding) {
            el.innerHTML = renderMarkdown(binding.value as string, binding.arg)
        }
    })
})
