<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
<div v-if="status === 'approved'" class="afterdark">
    <section class="hero grid vertical-center halves">
        <div class="clamped">
            <div class="content">
                <img class="logo" src="/images/teasers/afterdark/logo.png" :alt="$t('AFTER_DARK.LOGO_ALT')">
                <h3>{{ $t('AFTER_DARK.LANDING_PAGE_LEADING') }}</h3>
                <p v-html="$t('AFTER_DARK.LANDING_PAGE_INFO_CALLOUT')" />
                <div class="signup">
                    <p>{{ $t('AFTER_DARK.LANDING_PAGE_SIGNUP_CALLOUT') }}</p>
                    <EmailSignup
                        subscriber-id="TeaserPage"
                        :data-fields="[{ name: 'language', id: '4242', value: listrakLocale }]"
                        :button-text="$t('FORM.SUBMIT')"
                        button-style="outline"
                    />
                </div>
            </div>
            <div class="media">
                <img class="logo" src="/images/teasers/afterdark/logo.png" :alt="$t('AFTER_DARK.LOGO_ALT')">
                <button v-on:click="onVideoClick">
                    <img src="/images/teasers/afterdark/poster.jpg" alt="x">
                </button>
            </div>
        </div>
    </section>
</div>
<div v-else-if="status === 'denied'" class="birthday">
    <p class="large">{{ $t('AFTER_DARK.UNAUTHORIZED') }}</p>
</div>
<div v-else class="birthday">
    <p class="large">Enter your birthday</p>
    <div class="fields">
        <v-select v-model="monthValue" :clearable="false" :searchable="false" :options="month" class="dropdown" />
        <v-select v-model="dayValue" :clearable="false" :searchable="false" :options="day" class="dropdown" />
        <v-select v-model="yearValue" :clearable="false" :searchable="false" :options="year" class="dropdown" />
    </div>
    <div class="fields">
        <ButtonElement class="outline" v-on:click="storeDate" v-on:keydown.enter="storeDate">Submit</ButtonElement>
    </div>
</div>
</template>

<script lang="ts">
import { type Component, defineComponent } from 'vue'
import { I18n } from '$services/i18n'
import CarouselModal from '$components/carousel/CarouselModal.vue'
import EmailSignup from '$components/forms/EmailSignup.vue'
import ButtonElement from '$components/ButtonElement.vue'

type BirthdayStatus = 'denied' | 'unknown' | 'approved'

export default defineComponent({
    components: {
        EmailSignup,
        ButtonElement
    },

    data() {
        return {
            monthValue: { label: 'January', value: 1 },
            dayValue: { label: '1', value: 1 },
            yearValue: { label: '2024', value: 2024 },
            status: <BirthdayStatus> 'unknown'
        }
    },

    computed: {
        listrakLocale() {
            return I18n.getLocaleForListrak(this.$i18n.locale)
        },

        month() {
            const m = []
            const d = new Date()
            d.setDate(1)

            for (let i = 0; i < 12; i++) {
                d.setMonth(i)
                m.push({ label: this.$d(d, { month: 'long' }), value: (i + 1) })
            }

            return m
        },

        day() {
            const d = []
            for (let i = 1; i <= 31; i++) {
                d.push({ label: i.toString(), value: i })
            }
            return d
        },

        year() {
            const y = []
            const currentYear = new Date().getFullYear()
            for (let i = currentYear; i > currentYear - 120; i--) {
                y.push({ label: i.toString(), value: i })
            }
            return y
        }
    },

    mounted() {
        void this.checkBirthday()
    },

    methods: {
        checkBirthday() {
            const birthdayString = window.localStorage.getItem('birthday')

            if (birthdayString) {
                const birthday = Date.parse(birthdayString)
                const threshold = new Date()
                threshold.setFullYear(threshold.getFullYear() - 18)

                if (birthday < threshold.getTime()) {
                    this.status = 'approved'
                } else {
                    this.status = 'denied'
                }
            } else {
                this.status = 'unknown'
            }
        },

        storeDate() {
            const birthday = new Date()
            birthday.setFullYear(this.yearValue.value)
            birthday.setMonth(this.monthValue.value)
            birthday.setDate(this.dayValue.value)
            window.localStorage.setItem('birthday', birthday.toISOString())
            this.checkBirthday()
        },

        onVideoClick() {
            // TODO: we should teach this carousel how to deal
            // with data that didn't come from strapi
            void this.$showModal(CarouselModal as Component, {
                media: [{
                    attributes: {
                        alternativeText: this.$t('AFTER_DARK.LOGO_ALT'),
                        url: '/images/teasers/afterdark/poster.jpg'
                    }
                }],
                videoId: 'Dun3ye7EC6Q',
                itemIndex: 0
            })
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.afterdark {
    .hero {
        position: relative;
        margin: -90px 0 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image:
            linear-gradient(rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 1) 100%),
            radial-gradient(200% 115% at 90% 65%, rgba(26, 31, 38, 0) 15%, rgba(26, 31, 38, 0.69) 60%, rgb(26, 31, 38) 100%),
            url(/images/teasers/afterdark/bg.jpg);
    }

    .clamped {
        display: flex;
        flex-direction: row;
        gap: 30px;
        padding: 100px 20px;
    }

    .content {
        flex: 2 0 0;

        .logo {
            width: 100%;
            height: auto;
            animation: flicker 10s infinite;
        }

        p {
            margin-bottom: 10px;
        }
    }

    .media {
        flex: 3 0 0;

        .logo {
            display: none;
            animation: flicker 10s infinite;
        }

        button {
            cursor: pointer;
            width: 100%;
            aspect-ratio: 16/9;
            padding: 0;
            border: 2px solid transparent;
            border-radius: 10px;
            background: transparent;
            line-height: 0;
            overflow: hidden;
            transition: transform 0.1s, border 0.1s;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &:hover {
                border: 2px solid #fff;
                transform: scale(1.02);
            }
        }
    }

    @include mq-small-and-less {
        .clamped {
            max-width: 700px;
            gap: 0px;
            flex-direction: column-reverse;
            padding-bottom: 50px;
        }

        .content {
            flex: auto;

            .logo {
                display: none;
            }

            h3, p {
                text-align: center;
            }
        }

        .media {
            flex: auto;
            text-align: center;

            .logo {
                display: inline-block;
                width: 75%;
                max-width: 300px;
                margin-bottom: 20px;
            }
        }
    }
}

.birthday {
    margin: 70px auto;

    p {
        text-align: center;
        font-size: 16px;

        &.large {
            margin-top: 40px;
            font-size: 24px;
        }
    }
    .fields {
        width: 100%;
        gap: 10px;
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }
}

.dropdown {
    color: var(--neutral-300);
    font-family: Inter;
    font-size: 14px;
    max-height: 50px;

    @include mq-medium-and-more {
        font-size: 14px;
    }

    :deep(.vs__selected) {
        // HACK: This prevents the prior selected value from jumping up
        // visually when the dropdown list is open.
        // TODO: Consider migrating to https://github.com/RemoteDevForce/tom-select
        flex-grow: 1;
        position: static;
        padding: 0;

        .selected {
            flex-grow: 1;
        }
    }

    :deep(.vs__selected-options) {
        margin-left: 16px;

        @include mq-medium-and-less {
            margin-left: 4px;
        }
    }

    :deep(.vs__open-indicator) {
        height: 16px;
        width: 16px;
        margin-right: 16px;

        @include mq-medium-and-less {
            margin-right: 4px;
        }
    }

    :deep(.vs__dropdown-toggle) {
        max-height: 50px;
    }

    .selected {
        color: var(--neutral-300);
    }
}

</style>
