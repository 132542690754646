import revive_payload_client_4sVQNw7RlN from "/home/runner/work/jbg-web/jbg-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/jbg-web/jbg-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/jbg-web/jbg-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/jbg-web/jbg-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/jbg-web/jbg-web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/jbg-web/jbg-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/jbg-web/jbg-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/home/runner/work/jbg-web/jbg-web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/jbg-web/jbg-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cms_BHquPmbZ2Y from "/home/runner/work/jbg-web/jbg-web/src/plugins/cms.ts";
import plugin_8QVB3NKHAg from "/home/runner/work/jbg-web/jbg-web/src/plugins/i18n/plugin.ts";
import plugin_vACT9eUuhN from "/home/runner/work/jbg-web/jbg-web/src/plugins/modal/plugin.ts";
import plugin_HVs6w4F52v from "/home/runner/work/jbg-web/jbg-web/src/plugins/toast/plugin.ts";
import cookieConsent_ZG8yti6gaR from "/home/runner/work/jbg-web/jbg-web/src/plugins/cookieConsent.ts";
import auth_e0FkfVuy48 from "/home/runner/work/jbg-web/jbg-web/src/plugins/auth.ts";
import bb_YU6Mjhk1sr from "/home/runner/work/jbg-web/jbg-web/src/plugins/bb.ts";
import gamestream_EnR6dotPKP from "/home/runner/work/jbg-web/jbg-web/src/plugins/gamestream.ts";
import md_ZFs2Fid4ui from "/home/runner/work/jbg-web/jbg-web/src/plugins/md.ts";
import params_ohy56fInFf from "/home/runner/work/jbg-web/jbg-web/src/plugins/params.ts";
import report_7BcPqal8tQ from "/home/runner/work/jbg-web/jbg-web/src/plugins/report.ts";
import select_fkZYP3esEZ from "/home/runner/work/jbg-web/jbg-web/src/plugins/select.ts";
import shopify_DGWO89xc9t from "/home/runner/work/jbg-web/jbg-web/src/plugins/shopify.ts";
import user_pRMZBFhYe6 from "/home/runner/work/jbg-web/jbg-web/src/plugins/user.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  cms_BHquPmbZ2Y,
  plugin_8QVB3NKHAg,
  plugin_vACT9eUuhN,
  plugin_HVs6w4F52v,
  cookieConsent_ZG8yti6gaR,
  auth_e0FkfVuy48,
  bb_YU6Mjhk1sr,
  gamestream_EnR6dotPKP,
  md_ZFs2Fid4ui,
  params_ohy56fInFf,
  report_7BcPqal8tQ,
  select_fkZYP3esEZ,
  shopify_DGWO89xc9t,
  user_pRMZBFhYe6
]