import type { I18n } from '$services/i18n'

import en from '$i18n/en.json'

import fr from '$i18n/fr.json'
import it from '$i18n/it.json'
import de from '$i18n/de.json'
import es from '$i18n/es.json'
import esXL from '$i18n/es-XL.json'
import ptBR from '$i18n/pt-br.json'

type SupportedLocales =
    typeof en | 
    typeof fr | 
    typeof it | 
    typeof de | 
    typeof es | 
    typeof esXL | 
    typeof ptBR;

export const messages: I18n.Messages<SupportedLocales> = {
    en,
    fr,
    it,
    de,
    es,
    'es-XL': esXL,
    'es-419': esXL,
    'pt-br': ptBR
}
