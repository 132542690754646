declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $cms: CMS
    }
}

export namespace CMS {
    export interface InitOptions {
        baseUrl: string
        apiToken?: string
    }

    export interface FetchOptions {
        withMeta?: boolean
        fullResponse?: boolean
        locale?: string
        sort?: string
        fields?: string[]
        populate?: string[] | string
        filters?: Record<string, string|number>
        ids?: boolean
        pagination?: {
            withCount?: boolean
            page?: number
            pageSize?: number
            start?: number
            limit?: number
        }
    }

    export interface MetaResponse<T> {
        meta: CMS.Meta
        data: T
    }

    // Responses

    export interface Data<T> {
        id: number
        attributes: T
    }

    export interface Meta {
        pagination: {
            page: number
            pageSize: number
            pageCount: number
            total: number
        }
    }

    interface Error {
        status: number
        name: string
        message: string
        details: Record<string, any>
    }

    export interface Content<T> {
        data?: Data<T|T[]>
        meta?: Meta
        error?: Error
    }
}

class CMS {
    private baseUrl: string
    private requestOptions: RequestInit = {}

    constructor(options: CMS.InitOptions) {
        this.baseUrl = options.baseUrl

        if (options.apiToken) {
            this.requestOptions.headers = {
                Authorization: `Bearer ${options.apiToken}`
            }
        }
    }

    private async makeRequest(path: string, options: CMS.FetchOptions) {
        let locale = options.locale

        if (!options.locale) {
            // locale = I18n.locale
            throw new Error('No locale provided for fetch request!')
        }

        // convert i18n locale name to Strapi locale name
        if (locale === 'es-XL') {
            locale = 'es-419'
        }

        if (locale === 'pt-br') {
            locale = 'pt-BR'
        }

        let url = `${this.baseUrl}/${path}?locale=${locale}`

        if (options.sort) url += `&sort=${options.sort}`
        if (options.fields) url += this.arrayToRequest('fields', options.fields)

        if (options.populate) {
            Array.isArray(options.populate)
                ? url += this.arrayToRequest('populate', options.populate)
                : url += `&populate=${options.populate}`
        }

        if (options.filters) {
            Object.keys(options.filters).forEach((key) => {
                url += `&filters[${key}]=${encodeURIComponent(options.filters![key])}`
            })
        }

        if (options.pagination) {
            Object.keys(options.pagination).forEach((key) => { url += `&pagination[${key}]=${options.pagination![key as keyof CMS.FetchOptions['pagination']]}` })
        }

        return $fetch(url, this.requestOptions)
    }

    private arrayToRequest(type: string, items: Array<string>) {
        let str = ''
        items.forEach((val, i) => { str += `&${type}[${i}]=${val}` })
        return str
    }

    // TODO: the product endpoint doesn't take a slug. make slug optional here or perhaps we make a new fetch for products
    async fetchOne<T>(path: string, slug: string | null, options?: CMS.FetchOptions & { withMeta?: false }): Promise<T>
    async fetchOne<T>(path: string, slug: string | null, options: CMS.FetchOptions & { withMeta: true }): Promise<CMS.MetaResponse<T>>
    // eslint-disable-next-line complexity
    async fetchOne<T>(path: string, slug: string | null, options?: CMS.FetchOptions) {
        if (!options) options = {}
        if (!options.filters) options.filters = {}
        if (slug) options.filters.slug = slug

        const json = await this.makeRequest(path, options) as CMS.Content<T>
        if (json.error) throw new Error(json.error.message)
        if (!json.data) throw new Error('No data')
        if (Array.isArray(json.data) && !json.data.length) throw new Error('No data')

        let data = Array.isArray(json.data) ? json.data[0].attributes : json.data.attributes
        if (options.ids && data) {
            data.id = Array.isArray(json.data) ? json.data[0].id : json.data.id
        }

        // NOTE: this is being used to access the Strapi product id and
        // the shopifyData obj in data, since it lives outside of attributes.
        // Alternatively we could make a specific fetchOption for shopifyData, similar to withMeta?
        if (options.fullResponse) {
            data = json.data
        }

        return options.withMeta ? { meta: json.meta, data } : data
    }

    async fetchMany<T extends any[]>(path: string, options?: CMS.FetchOptions & { withMeta?: false }): Promise<T>
    async fetchMany<T extends any[]>(path: string, options: CMS.FetchOptions & { withMeta: true }): Promise<CMS.MetaResponse<T>>
    async fetchMany<T extends any[]>(path: string, options?: CMS.FetchOptions) {
        if (!options) options = {}
        if (!options.pagination) options.pagination = { limit: 100 }

        const json = await this.makeRequest(path, options) as CMS.Content<T>
        if (json.error) throw new Error(json.error.message)
        if (!json.data) throw new Error('No data')
        if (!Array.isArray(json.data)) throw new Error('Not a many request')

        const data = json.data.map((item) => item.attributes)

        return options.withMeta ? { meta: json.meta, data } : data
    }
}

export default defineNuxtPlugin((_nuxtApp) => {
    const runtimeConfig = useRuntimeConfig()
    const cmsProvider = new CMS(<CMS.InitOptions>runtimeConfig.public.cms)
    return {
        provide: {
            cms: cmsProvider
        }
    }
})
