<!-- eslint-disable vue/no-v-html -->
<template>
<div class="view">
    <div
        class="close-btn" role="button" tabindex="0"
        :aria-label="$t('WIDGETS.CLOSE')" v-on:click="close"
        v-on:keydown.enter="close"
    >
        <i class="fa-regular fa-xmark" />
    </div>
    <div class="container">
        <h1 class="header">Check your email</h1>
        <div class="msg">
            <p>
                Please check your inbox and retrieve the 6-digit code we sent you and enter a new password.
            </p>
        </div>
        <div class="form">
            <form class="flex-column password-form" novalidate v-on:submit.prevent="onSubmit">
                <FormErrorToolTip v-if="code.invalid && codeErrText" :error-text="codeErrText" />
                <label for="code" class="label">Code</label>
                <input
                    v-model="code.value"
                    type="text" name="code"
                    maxlength="6" minLength="6"
                    required class="input field"
                    :class="{ invalid: code.invalid }" v-on:blur="validateCode()"
                >

                <FormErrorToolTip v-if="password.invalid && passwordErrText" :error-text="passwordErrText" />
                <label for="password" class="label">{{ $t("FORM.PASSWORD_ENTER") }}</label>
                <input
                    v-if="!showPassword" id="password" ref="password"
                    v-model="password.value"
                    type="password" name="password"
                    minLength="6" autocomplete="new-password" required
                    class="input password-field"
                    :class="{ invalid: password.invalid }" v-on:blur="validatePassword()"
                    v-on:keydown="clearErrors()"
                >
                <input
                    v-else id="password" v-model="password.value"
                    type="text" name="password" minLength="6"
                    autocomplete="new-password" required class="input password-field"
                    :class="{ invalid: password.invalid }" v-on:blur="validatePassword()"
                >
                <span
                    class="password-toggle-icon" v-on:click="toggleShowPassword"
                    v-on:keydown.enter="toggleShowPassword"
                ><i
                    id="eyeball-icon" class="fa-regular"
                    :class="{ 'fa-eye-slash': !showPassword, 'fa-eye': showPassword }"
                /></span>
                <FormErrorToolTip v-if="confirmPassword.invalid && confirmPasswordErrText" :error-text="confirmPasswordErrText" />
                <label for="confirmPassword" class="label">{{ $t('FORM.PASSWORD_SET_CONFIRM') }}</label>
                <input
                    v-if="!showPassword" id="confirmPassword" v-model="confirmPassword.value"
                    type="password" name="confirmPassword" minLength="6"
                    required class="input confirm-password-field"
                    autocomplete="new-password"
                    :class="{invalid: confirmPassword.invalid}"
                    v-on:blur="validateConfirmPassword()"
                    v-on:keydown="clearTypingTimer(typingTimer)"
                    v-on:keyup="startTypingTimer('confirmPassword')"
                >
                <input
                    v-else id="confirmPassword" v-model="confirmPassword.value"
                    type="text" name="confirmPassword" minLength="6"
                    required class="input confirm-password-field"
                    :class="{invalid: confirmPassword.invalid}"
                    v-on:blur="validateConfirmPassword()"
                    v-on:keydown="clearTypingTimer(typingTimer)"
                    v-on:keyup="startTypingTimer('confirmPassword')"
                >
                <div class="submit-btn-container">
                    <ButtonElement
                        type="submit" class="submit-btn" :disabled="!canSubmit"
                        v-on:click="confirmForgetPassword"
                        v-on:keydown.enter="confirmForgetPassword"
                    >
                        {{ $t("FORM.CONTINUE") }}
                    </ButtonElement>
                </div>
            </form>
        </div>
        <div class="forgot-pw-msg">
            Didn't receive a code?
            <a
                :class="{disabled: isLinkDisabled}"
                class="link" v-on:click="onForgotPassword"
                v-on:keydown.enter="onForgotPassword"
            >
                {{ !isLinkDisabled ? $t(`ACCOUNT.VERIFY.RESEND_LINK`) : $t(`ACCOUNT.VERIFY.LINK_SENT`) }}
            </a>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { JBGWeb } from '$types/JBGWeb'
import { CurrentUser } from '$services/user'
import { JBGApi } from '$services/api'
import ButtonElement from '$components/ButtonElement.vue'
import FormErrorToolTip from '$components/forms/FormErrorToolTip.vue'

export default defineComponent({
    components: { ButtonElement, FormErrorToolTip },
    props: {
        email: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isLinkDisabled: false,
            showPassword: false,
            password: { value: '', invalid: false } as JBGWeb.UserFormFieldData,
            confirmPassword: { value: '', invalid: false } as JBGWeb.UserFormFieldData,
            code: { value: '', invalid: false } as JBGWeb.UserFormFieldData,
            typingTimer: <ReturnType<typeof setTimeout> | null> null
        }
    },
    head() {
        return {
            title: 'Jackbox Games - Forgot Password'
        }
    },

    computed: {
        canSubmit() {
            if (this.password.value === '') return false
            if (!this.password.invalid && !this.confirmPassword.invalid) return true
            return false
        },

        passwordErrText(): string | null {
            // do not show error if code has error
            if (this.code.invalid) return null
            switch (this.password.invalidationReason) {
            case 'passwordBlank':
                return this.$t('FORM.ERRORS.PASSWORD_BLANK')
            case 'passwordLength':
                return this.$t('FORM.ERRORS.PASSWORD_LENGTH')
            case 'passwordPattern':
                return this.$t('FORM.ERRORS.PASSWORD_PATTERN')
            default:
                return null
            }
        },

        confirmPasswordErrText(): string | null {
            // do not show error if code or pwd has error
            if (this.password.invalid || this.code.invalid) return null
            switch (this.confirmPassword.invalidationReason) {
            case 'passwordConfirmBlank':
                return this.$t('FORM.ERRORS.PASSWORD_CONFIRM_BLANK')
            case 'passwordsNotMatch':
                return this.$t('FORM.ERRORS.PASSWORD_CONFIRM_NOT_MATCH')
            default:
                return null
            }
        },
        codeErrText(): string | null {
            switch (this.code.invalidationReason) {
            case 'codeLength':
                return 'Code needs to be 6 digits'
            case 'codeNotNumber':
                return 'Code must be numeric'
            default:
                return null
            }
        }
    },

    watch: {
        showPassword() {
            const eyeballIcon = document.getElementById('eyeball-icon')
            if (!eyeballIcon) return
            if (!this.showPassword) {
                eyeballIcon.classList.remove('fa-eye')
                eyeballIcon.classList.add('fa-regular', 'fa-eye-slash')
            }

            if (this.showPassword) {
                eyeballIcon.classList.remove('fa-eye-slash')
                eyeballIcon.classList.add('fa-regular', 'fa-eye')
            }
        }
    },
    methods: {
        close() {
            void this.$router.push({
                name: 'login'
            })
        },
        clearErrors() {
            this.password.invalid = false
            this.password.invalidationReason = undefined
        },
        getEmail(): string|undefined {
            return this.$route.query.email?.toString() || CurrentUser.email
        },
        navigateToProfile() {
            void this.$router.push({
                name: 'userProfile'
            })
        },
        navigateToSupport() {
            void this.$router.push({
                name: 'support'
            })
        },
        async onForgotPassword() {
            const email = this.getEmail()
            if (!email) {
                console.error('error, no email')
                return
            }
            this.isLinkDisabled = true
            await this.$user.forgotPassword(email)
            void this.$router.push({
                name: 'forgotPassword'
            })
        },

        onSubmit() {
            return false
        },

        toggleShowPassword() {
            this.showPassword = !this.showPassword
        },

        validatePassword() {
            if (!this.password.value) return

            // password is blank
            if (this.password.value.length === 0) {
                this.password.invalid = true
                this.password.invalidationReason = 'passwordBlank'
                return
            }
            // password length invalid
            if (this.password.value.length < 8) {
                this.password.invalid = true
                this.password.invalidationReason = 'passwordLength'
                return
            }
            // password doesn't match pattern
            // explaination of this regex here: https://stackoverflow.com/questions/58767980/aws-cognito-password-regex-specific-to-aws-cognito
            // eslint-disable-next-line max-len
            const pwdRegex = /^(?!\s+)(?!.*\s+$)(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
            const valid = pwdRegex.test(this.password.value)
            if (!valid) {
                this.password.invalid = true
                this.password.invalidationReason = 'passwordPattern'
                return
            }
            this.validateConfirmPassword()
            this.password.invalid = false
        },

        validateConfirmPassword() {
            if (!this.confirmPassword.value) return
            // confirm password doesn't match password
            if (this.confirmPassword.value && this.confirmPassword.value !== this.password.value) {
                this.confirmPassword.invalid = true
                this.confirmPassword.invalidationReason = 'passwordsNotMatch'
                return
            }
            this.confirmPassword.invalid = false
        },
        validateCode() {
            if (!this.code.value) return

            // code 6 digits
            if (this.code.value.length !== 6) {
                this.code.invalid = true
                this.code.invalidationReason = 'codeLength'
                return
            }

            const numericRegex = /^-?\d+$/
            const valid = numericRegex.test(this.code.value)
            if (!valid) {
                this.code.invalid = true
                this.code.invalidationReason = 'codeNotNumber'
                return
            }
            this.code.invalid = false
        },
        clearTypingTimer(timer: ReturnType<typeof setTimeout> | null) {
            if (!timer) return
            clearTimeout(timer)
        },

        startTypingTimer(fieldName: string) {
            this.clearTypingTimer(this.typingTimer)
            const DONE_TYPING_INTERVAL = 1500
            switch (fieldName) {
            case 'password':
                this.password.invalid = false
                this.typingTimer = setTimeout(this.validatePassword, DONE_TYPING_INTERVAL)
                break
            case 'confirmPassword':
                this.confirmPassword.invalid = false
                this.typingTimer = setTimeout(this.validateConfirmPassword, DONE_TYPING_INTERVAL)
                break
            }
        },
        async confirmForgetPassword() {
            if (this.code.invalid || this.password.invalid || this.confirmPassword.invalid) return
            const email = this.getEmail()
            if (!email) {
                console.error('error, no email')
                return
            }
            try {
                await this.$user.confirmForgotPassword(email, this.code.value, this.password.value)
                await this.$user.emailLogin(email, this.password.value)
                void this.$router.push({
                    name: 'playInBrowser',
                    params: {
                        slug: 'quixort'
                    }
                })
            } catch (error) {
                if (error && error instanceof JBGApi.Error) {
                    if (error.code === 2004) {
                        console.error(error)
                    }
                } else {
                    this.$toast.add({
                        id: 'password',
                        type: 'error',
                        text: this.$t('GENERAL.ERROR')
                    })
                }
            }
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.view {
    position: relative;
    margin-top: -88px;
    min-height: 100vh;
    z-index: 101;
    width: 100%;
    background: var(--surface-900);

    @include mq-small-and-less {
        margin-top: -56px;
    }
}

.close-btn {
    margin: 40px 64px 0px 0px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;

    &:hover {
        cursor: pointer;
    }

    svg {
        width: 36px;
        height: 36px;
    }

    @include mq-xsmall {
        svg {
            width: 30px;
            height: 30px;
        }

        margin: 20px 24px 0px 0px;
    }
}

.container {
    align-items: center;
    background: var(--surface-900);
    display: flex;
    flex-direction: column;
    gap: 16px;

    margin: 0 auto;

    padding-top: 72px;
    width: 440px;

    @include mq-xsmall {
        align-items: stretch;
        width: 100%;
    }

    .header {
        text-transform: uppercase;
        font-size: 28px;
        text-align: center;
        order: 1;
        @include mq-xsmall {
            text-align: left;
            padding: 0 16px;
            font-size: 24px;
            line-height: 32px;
        }
    }

    .msg {
        order: 2;
        align-items: center;
        background: radial-gradient(43.66% 50% at 50% 50%, rgba(163, 210, 252, 0.05) 52.03%, rgba(166, 213, 255, 0.00) 100%);

        font-size: 18px;
        font-weight: 400;
        line-height: 18px;

        p {
            width: 100%;
            margin-bottom: 24px;
        }

        @include mq-xsmall {
            text-align: left;
            padding: 0 16px;
            font-size: 16px;
            width: 100%;
        }
    }

    .form{
        order: 3;
        width: 100%;
        .password-form {
            align-items: stretch;
            text-align: left;

            .label {
                color: var(--neutral-50);
                font-size: 14px;
                font-weight: 600;
                line-height: 14px;
                text-transform: uppercase;
            }

            .input {
                color: var(--neutral-300);
                color-scheme: dark;
                font-size: 18px;
                font-weight: 400;
                height: 56px;
                line-height: 18px;
                margin: 8px 0 20px 0;
                padding: 16px;

                &:active,
                &:focus {
                    outline: none;
                }

                &.invalid {
                    border-bottom: 2px solid var(--red-300);
                }
            }

            button {
                width: 100%;
                font-size: 16px;
                font-weight: 500;
            }

            .password-field {
                margin-bottom: 0px;
            }

            .password-toggle-icon {
                cursor: pointer;
                align-self: end;
                position: relative;
                bottom: 36px;
                right: 16px;
            }

            .submit-btn-container {
                padding-top: 8px;
            }
        }
    }

    .forgot-pw-msg {
        order: 4;
        margin-top: 16px;
        text-align: center;
        width: 100%;
        a {
            cursor: pointer;
            &.disabled {
                cursor: text;
                color: var(--green-300);
            }
        }
    }
}
</style>
