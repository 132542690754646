import type { Plugin } from 'vue'

import * as CookieConsent from 'vanilla-cookieconsent'
import type { CookieConsentConfig, Translation } from 'vanilla-cookieconsent'

import { I18n } from '$services/i18n'
import { messages } from './i18n/messages'

import 'vanilla-cookieconsent/dist/cookieconsent.css'

/**
 * This shows a banner that informs users of our cookie policy and lets them opt
 * in or out of them.
 */

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $cc: typeof CookieConsent
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.config.globalProperties.$cc = CookieConsent
    void nuxtApp.vueApp.config.globalProperties.$cc.run(<CookieConsentConfig>{
        // TODO: Figure out if we should do anything special when a user
        // interacts with the consent modal
        onChange: ({ cookie }) => {
            console.log('user changed their cookie preferences:\n', cookie)
        },
        onFirstConsent: ({ cookie }) => {
            console.log('user set cookie preferences for the first time:\n', cookie)
        },
        revision: 1,
        guiOptions: {
            consentModal: {
                layout: 'bar inline',
                position: 'bottom',
                equalWeightButtons: true,
                flipButtons: true
            },
            preferencesModal: {
                layout: 'box',
                position: 'right',
                equalWeightButtons: true,
                flipButtons: false
            }
        },
        categories: {
            necessary: {
                readOnly: true
            }
        },
        language: {
            default: 'en',
            autoDetect: 'browser',
            translations: I18n.supported.reduce((translations, language) => {
                if (!messages[language]?.COOKIE) {
                    return translations
                }
                translations[language] = {
                    consentModal: {
                        label: messages[language]!.COOKIE.CONSENT_MODAL.ARIA,
                        description: messages[language]!.COOKIE.DESCRIPTION,
                        acceptAllBtn: messages[language]!.COOKIE.ACCEPT_ALL,
                        acceptNecessaryBtn: messages[language]!.COOKIE.REJECT_ALL
                    },
                    // NOTE: No current plans to show the full preferences
                    // modal. Revisit this if we start having multiple
                    // categories of cookies to store.
                    preferencesModal: {
                        // title: 'Consent Preferences',
                        // acceptAllBtn: messages[language]!.COOKIE.ACCEPT_ALL,
                        // acceptNecessaryBtn: messages[language]!.COOKIE.REJECT_ALL
                        // savePreferencesBtn: 'Save preferences',
                        // closeIconLabel: 'Close modal',
                        // serviceCounterLabel: 'Service|Services',
                        sections: [
                            // {
                            //     title: 'Cookie Usage',
                            //     description: 'Lorem ipsum dolor sit amet'
                            // },
                            // {
                            //     title: 'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                            //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            //     linkedCategory: 'necessary'
                            // },
                            // {
                            //     title: 'More information',
                            //     description: 'For questions about our policy on cookies and your choices,
                            //     please <a class="cc__link" href="#yourdomain.com">contact us</a>.'
                            // }
                        ]
                    }
                }
                return translations
            }, <Record<string, Translation>>{})
        }
    })
})
