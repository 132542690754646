<template>
<div>Exchanging Tokens....</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { JBGApi } from '$services/api'
import { CurrentUser, User } from '$services/user'

export default defineComponent({
    mounted() {
        void this.setup()
    },
    methods: {
        async setup() {
            const code = this.$route.query.code!.toString()
            const state = this.$route.query.state?.toString()
            const stateVerify = sessionStorage.getItem(`codeVerifier-${state}`)
            sessionStorage.removeItem(`codeVerifier-${state}`)
            if (stateVerify === null) {
                throw new Error('invalid auth verification')
            }

            // This 201/202 check can probably be moved to the exchangeCode method. I'm unsure on error
            // handling in the web project and this will be changed quite a bit before release.
            await this.$authService.exchangeCode(code, stateVerify).then((res) => {
                if (res.status === 201) {
                    CurrentUser.setStatus(User.Status.CreatingBySSO)
                    sessionStorage.removeItem('redirect')
                    void this.$router.push({
                        name: 'login',
                        params: {
                            step: 'create-user-profile'
                        }
                    })
                } else if (res.status === 202) {
                    const redirect = sessionStorage.getItem('redirect')
                    if (redirect !== null) {
                        window.location.href = redirect
                    }
                } else {
                    console.error('invalid auth code')
                }
            }).catch((e) => {
                if (e && e instanceof JBGApi.Error) {
                    if (e.httpStatus === 401) {
                        // not authorized, send to not on allowlist screen.
                        void this.$router.push({
                            name: 'login',
                            params: {
                                step: 'unauthorized'
                            }
                        })
                    }
                } else {
                    console.error(e)
                    this.$toast.add({
                        id: 'token',
                        type: 'error',
                        text: this.$t('GENERAL.ERROR')
                    })
                }
            })
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;
</style>
