<!-- eslint-disable vue/no-v-html -->
<template>
<div class="verify">
    <h1 class="verify-header">{{ $t(`ACCOUNT.${state}.HEADER`) }}</h1>
    <div class="verify-image">
        <img :src="getImage()" alt="Sad robot">
    </div>
    <div class="msg">
        <p>
            {{ $t(`ACCOUNT.${state}.MSG`) }}
            <span v-if="state === 'VERIFY_ERROR'">
                <a
                    v-if="getEmail()" :class="{disabled: isLinkDisabled}"
                    v-on:click="resendVerificationEmail"
                    v-on:keydown.enter="resendVerificationEmail"
                >
                    {{ !isLinkDisabled ? $t(`ACCOUNT.${state}.RESEND_LINK`) : $t(`ACCOUNT.${state}.LINK_SENT`) }}
                </a>
            </span>
        </p>
        <p v-if="state === 'VERIFY'">
            {{ $t(`ACCOUNT.${state}.MSG2`) }}
            <a :class="{disabled: isLinkDisabled}" v-on:click="resendVerificationEmail" v-on:keydown.enter="resendVerificationEmail">
                {{ !isLinkDisabled ? $t(`ACCOUNT.${state}.RESEND_LINK`) : $t(`ACCOUNT.${state}.LINK_SENT`) }}
            </a>
        </p>
        <p v-else-if="state === 'VERIFIED'">
            <ButtonElement class="btn" v-on:click="navigateToProfile">Continue</ButtonElement>
        </p>
        <p v-else-if="state === 'VERIFY_ERROR'">
            {{ $t(`ACCOUNT.${state}.MSG2`) }}
            <a target="_blank" v-on:click="navigateToSupport" v-on:keydown.enter="navigateToSupport">
                {{ $t(`ACCOUNT.${state}.SUBMIT_TICKET`) }}
            </a>
            {{ $t(`ACCOUNT.${state}.MSG3`) }}
        </p>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { JBGApi } from '$services/api'
import { CurrentUser } from '$services/user'
import ButtonElement from '$components/ButtonElement.vue'

const STATE = {
    VERIFY: 'VERIFY',
    VERIFYING: 'VERIFYING',
    VERIFIED: 'VERIFIED',
    VERIFY_ERROR: 'VERIFY_ERROR'
}

export default defineComponent({
    components: { ButtonElement },
    data() {
        return {
            isLinkDisabled: false,
            state: STATE.VERIFY
        }
    },
    head() {
        return {
            title: `Jackbox Games - ${this.$t('ACCOUNT.EMAIL_TITLE')}`
        }
    },
    mounted() {
        void this.setup()
    },
    methods: {
        async setup() {
            const code = this.$route.query.code?.toString()
            const email = this.$route.query.email?.toString()
            this.state = STATE.VERIFY

            if (code && email) {
                this.state = STATE.VERIFYING
                await this.$user.verifyEmail(code, email).then(() => {
                    this.state = STATE.VERIFIED
                }).catch((e) => {
                    this.state = STATE.VERIFY_ERROR
                    if (e && e instanceof JBGApi.Error) {
                        console.error(e.code, e.error, e.httpStatus)
                    } else {
                        this.$toast.add({
                            id: 'emailverify',
                            type: 'error',
                            text: this.$t('GENERAL.ERROR')
                        })
                    }
                })
            }
        },
        getEmail(): string|undefined {
            return this.$route.query.email?.toString() || CurrentUser.email
        },
        async resendVerificationEmail() {
            if (this.isLinkDisabled) return

            const email = this.getEmail()
            if (!email) {
                this.state = STATE.VERIFY_ERROR
                return
            }
            this.isLinkDisabled = true
            await this.$user.sendVerification(email).catch((e) => {
                this.state = STATE.VERIFY_ERROR
                if (e && e instanceof JBGApi.Error) {
                    console.error(e.code, e.error, e.httpStatus)
                } else {
                    this.$toast.add({
                        id: 'emailverify2',
                        type: 'error',
                        text: this.$t('GENERAL.ERROR')
                    })
                }
            })
        },
        getImage(): string {
            let robot = 'sad'
            switch (this.state) {
            case STATE.VERIFY:
            case STATE.VERIFYING:
                robot = 'thinking'
                break
            case STATE.VERIFIED:
                robot = 'happy'
                break
            case STATE.VERIFY_ERROR:
            default:
                robot = 'sad'
                break
            }
            return `/images/play/${robot}-bot.png`
        },
        navigateToProfile() {
            void this.$router.push({
                name: 'userProfile'
            })
        },
        navigateToSupport() {
            void this.$router.push({
                name: 'support'
            })
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.verify {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 480px;
    margin: 0 auto;
    gap: 16px;
    @include mq-xsmall {
        align-items: stretch;
        width: 100%;
    }

    .verify-header {
        text-transform: uppercase;
        font-size: 28px;
        text-align: center;
        order: 1;
        @include mq-xsmall {
            text-align: left;
            padding: 0 16px;
            font-size: 24px;
            line-height: 32px;
        }
    }

    .msg {
        order: 3;
        align-items: center;
        background: radial-gradient(43.66% 50% at 50% 50%, rgba(163, 210, 252, 0.05) 52.03%, rgba(166, 213, 255, 0.00) 100%);

        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        width: 440px;

        button {
            width: 100%;
            height: 48px;
            font-size: 16px;
            font-weight: 500;
        }

        p {
            width: 100%;
            margin-bottom: 24px;

            a {
                cursor: pointer;
                &.disabled {
                    cursor: text;
                    color: var(--green-300);
                }
            }
        }

        @include mq-xsmall {
            text-align: left;
            padding: 0 16px;
            font-size: 16px;
            width: 100%;
        }
    }

    .verify-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-image: url('/images/play/laser-bg.png');
        background-repeat: no-repeat;
        border-radius: 8.651px;
        height: 232px;

        margin-bottom: 24px;
        width: 100%;
        order: 2;

        img {
            width: 164px ;
        }

        @include mq-xsmall {
            border-radius: 0px !important;
            background-size: 105% 105%;
            background-position: center;
            height: 286px;
            order: -1;
        }
    }
}
</style>
