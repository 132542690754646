import { GameStream } from "$services/gamestream"

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $gamestream: GameStream
    }
}

export default defineNuxtPlugin({
    name: 'gamestream',
    setup(nuxtApp) {
        const runtimeConfig = useRuntimeConfig()
        const options = runtimeConfig.public.gameStream
        const gameStream = new GameStream(options)
        return {
            provide: {
                gamestream: gameStream
            }
        }
    }
})
