import type { Plugin } from 'vue'
import { GameCast } from '$libs/gamecastsdk-2.3.0'
import type { JBGWeb } from '$types/JBGWeb'
import { JBGApi } from './api'

export class GameStream extends JBGApi {
    private gamecast: GameCast
    public videoEl: HTMLVideoElement

    // callback functions
    private connectionStateFn: ((data: string) => void) | undefined
    private applicationMessageFn: ((data: GameStream.GameMessage) => void) | undefined
    private channelErrorFn: ((data: any) => void) | undefined
    private serverDisconnectFn: ((data: string) => void) | undefined

    constructor(options: GameStream.Options) {
        super(options.baseUrl)
        
        if (process.client) {
            // note: we could initialize the plugin here instead if we need/want to
            this.videoEl = document.createElement('video')
            this.videoEl.setAttribute('id', 'game-stream')
            this.videoEl.autoplay = true
            this.videoEl.playsInline = true
            this.videoEl.disablePictureInPicture = true
            this.videoEl.removeAttribute('controls')

            this.gamecast = new GameCast({
                videoElement: this.videoEl,
                inputConfiguration: {
                    autoMouse: true,
                    autoKeyboard: true,
                    autoGamepad: true,
                    hapticFeedback: true,
                    setCursor: 'visibility',
                    autoPointerLock: 'fullscreen'
                },
                clientConnection: {
                    connectionState: (data) => this.connectionState(data),
                    applicationMessage: (data) => this.applicationMessage(data),
                    channelError: (data) => this.channelError(data),
                    serverDisconnect: (data) => this.serverDisconnect(data)
                }
            })
        }
    }

    // regsiterCallbacks is the hook from the component
    public registerCallbacks(
        applicationMessage? : (data: GameStream.GameMessage) => void,
        connectionState?: (data: string) => void,
        channelError?: (data: any) => void,
        serverDisconnect?: (data: string) => void
    ) {
        this.connectionStateFn = connectionState
        this.applicationMessageFn = applicationMessage
        this.channelErrorFn = channelError
        this.serverDisconnectFn = serverDisconnect
    }

    public async createStream(signalRequest: string, applicationId: string): Promise<GameStream.CreateStreamResults> {
        const route = 'stream'
        const options = this.buildOptions('POST', { signalRequest, applicationId })

        return this.callAPI(route, options)
    }

    public async getStreamSession(token: string): Promise<JBGWeb.StreamSession> {
        const route = `stream/${token}`
        const options = this.buildOptions('GET')
        return this.callAPI(route, options)
    }

    public async getGame(slug: string): Promise<JBGWeb.Game|JBGApi.Error> {
        const route = `game/${slug}`
        const options = this.buildOptions('GET')
        return this.callAPI(route, options)
    }

    public async generateSignalRequest() {
        const generatedSignal = await this.gamecast.generateSignalRequest()
        return generatedSignal
    }

    public async processSignalResponse(signalResponse: string): Promise<void> {
        try {
            await this.gamecast.processSignalResponse(signalResponse)
        } catch (error) {
            console.error(error)
            // terminate the pending stream on failure
            this.gamecast.close()
        }
    }

    // sends a signal to terminate the gamecast stream
    public closeStream() {
        this.gamecast.close()
    }

    // attach input enables any automatic event listeners that were specified in the input configuration.
    public attachInput() {
        this.gamecast.attachInput()
    }

    // sendApplicationMessage takes a ClientMessage and sends it as a JSON string to the game.
    public sendApplicationMessage(obj: GameStream.ClientMessage) {
        const enc = new TextEncoder()
        const objStr = JSON.stringify(obj)
        const objArr = enc.encode(objStr)
        this.gamecast.sendApplicationMessage(objArr)
    }

    // callback functions from gamecast. These need to be registered by the component using the stream through `regsiterCallbacks`
    private connectionState(data: string) {
        if (this.connectionStateFn) this.connectionStateFn(data)
    }

    // applicationMessage takes a Uint8Array from the game and builds a GameMessage for the client to consume
    private applicationMessage(data: Uint8Array) {
        if (this.applicationMessageFn) {
            const dec = new TextDecoder()
            const json = dec.decode(data)
            const obj = JSON.parse(json)
            const msg = this.buildGameMessage(obj)
            this.applicationMessageFn(msg)
        }
    }
    private channelError(data: any) {
        console.error('channel error', data)
        if (this.channelErrorFn) this.channelErrorFn(data)
    }
    private serverDisconnect(data: string) {
        console.warn('server disconnect', data)
        if (this.serverDisconnectFn) this.serverDisconnectFn(data)
    }

    // buildGameMessage checks that the message returned from the game is of type GameMessage
    // and returns the valid concrete type. Throws an Error if the message is invalid
    private buildGameMessage(obj: any) : GameStream.GameMessage {
        const isValid = 'msg' in obj && typeof obj.msg === 'string'
            && 't' in obj && typeof obj.t === 'string'
        if (!isValid) throw new Error('invalid message from game stream')

        switch (obj.msg) {
        case 'ad:start':
            return new GameStream.AdStart()
        case 'game:loaded':
            return new GameStream.GameLoaded()
        default:
            throw new Error('invalid message type')
        }
    }
}

export namespace GameStream {
    export interface Options {
        baseUrl: string
    }

    export interface CreateStreamResults {
        token: string
    }

    // Game to client
    export enum GameMessageType {
        AdStart,
        GameLoaded
    }

    // Client to game
    export enum ClientMessageType {
        AdStarted,
        AdCompleted,
        AdFailed
    }

    // Message is the base message type for all communication
    export abstract class Message {
        msg: string
        t: string
        data: any
        constructor(msg: string, data?: any) {
            this.msg = msg
            this.t = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
            this.data = data
        }
    }

    // GameMessage the base message type for all messages from the game to the client
    export abstract class GameMessage extends Message {
        abstract readonly type: GameMessageType
    }

    // ClientMessage the base message type for all messages from the client to the game
    export abstract class ClientMessage extends Message {
        abstract readonly type: ClientMessageType
    }

    // Client to Game messages
    export class AdStarted extends ClientMessage {
        readonly type = ClientMessageType.AdStarted
        constructor() {
            super('ad:started')
        }
    }

    export class AdCompleted extends ClientMessage {
        readonly type = ClientMessageType.AdCompleted
        constructor() {
            super('ad:completed')
        }
    }

    export class AdFailed extends ClientMessage {
        readonly type = ClientMessageType.AdFailed
        constructor(data: string) {
            super('ad:failed', data)
        }
    }

    // Game to Client messages
    export class AdStart extends GameMessage {
        readonly type = GameMessageType.AdStart
        constructor() {
            super('ad:start')
        }
    }

    export class GameLoaded extends GameMessage {
        readonly type = GameMessageType.GameLoaded
        constructor() {
            super('game:loaded')
        }
    }
}
