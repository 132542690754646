import { default as _404I3w4LJMgaDMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/404.vue?macro=true";
import { default as _5008J1FrBOL17Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/500.vue?macro=true";
import { default as _91post_45slug_93xTQBGZjIZiMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue?macro=true";
import { default as indexA8cZown3tvMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue?macro=true";
import { default as champd_45up_45slam_45down_45rulesq5die7ZAddMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue?macro=true";
import { default as companyTeIkuZ5QSYMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/company.vue?macro=true";
import { default as cmsjXwgvXJSjFMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue?macro=true";
import { default as env9FMLxh3JRZMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue?macro=true";
import { default as _91_46_46_46doc_45path_93bnszFk4RZLMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue?macro=true";
import { default as indexrwyhvVPykZMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue?macro=true";
import { default as _91game_45type_93Jp7uBiS5BVMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[game-type].vue?macro=true";
import { default as indexht5dXWRn4dMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue?macro=true";
import { default as _91game_45slug_93yFRhSmvz1iMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue?macro=true";
import { default as _91bundle_45slug_93tH9UZhZSF3Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue?macro=true";
import { default as indexfiih8saSNiMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/index.vue?macro=true";
import { default as indexPMdrJIgED0Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue?macro=true";
import { default as _91pack_45slug_93p9ixEkCerOMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue?macro=true";
import { default as indexhJrPnC150BMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue?macro=true";
import { default as giftmbcvizTL7nMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue?macro=true";
import { default as how_45to_45playVVRUHfbLkKMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue?macro=true";
import { default as indexuRmSTRB6icMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/index.vue?macro=true";
import { default as jackbox_45at_45workHO1zr6A9GjMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue?macro=true";
import { default as jackbox_45for_45eventsyMN3nKOJZOMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue?macro=true";
import { default as jackbox_45for_45familiesyrXQpzlGKaMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue?macro=true";
import { default as jackbox_45for_45game_45nightZFgT7n3KjzMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue?macro=true";
import { default as jackbox_45for_45streamersq2zK2UarKZMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue?macro=true";
import { default as jackbox_45for_45studentsrPvv31zCxDMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue?macro=true";
import { default as jobsjn4P50HT8dMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue?macro=true";
import { default as loginpJbw2IDcKUMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/login.vue?macro=true";
import { default as mysterious_45teaserKfS18hE3RAMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue?macro=true";
import { default as indexDapNxU7YqPMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue?macro=true";
import { default as preference_45centerWnl7uDuVQjMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue?macro=true";
import { default as presskit7jVutcqXBnMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue?macro=true";
import { default as privacy_45policyHVbNabwlNsMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue?macro=true";
import { default as _91tab_45slug_93tM7aQl65i4Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/shop/[tab-slug].vue?macro=true";
import { default as indexmg4DokeYu7Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue?macro=true";
import { default as supportkIOMPEv5DKMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/support.vue?macro=true";
import { default as terms_45of_45serviceHiG6WYOnLgMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue?macro=true";
import { default as the_45jackbox_45megapickerz256JihBVPMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue?macro=true";
import { default as tokenAd3wHcwDQmMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/token.vue?macro=true";
import { default as forgotpasswordJ2iIcuCsApMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword.vue?macro=true";
import { default as profileP8xjiyB0BqMeta } from "/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue?macro=true";
import { default as verifyAwZaSzCmO3Meta } from "/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue?macro=true";
export default [
  {
    name: _404I3w4LJMgaDMeta?.name ?? "404___en",
    path: _404I3w4LJMgaDMeta?.path ?? "/404",
    meta: _404I3w4LJMgaDMeta || {},
    alias: _404I3w4LJMgaDMeta?.alias || [],
    redirect: _404I3w4LJMgaDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _404I3w4LJMgaDMeta?.name ?? "404___fr",
    path: _404I3w4LJMgaDMeta?.path ?? "/fr/404",
    meta: _404I3w4LJMgaDMeta || {},
    alias: _404I3w4LJMgaDMeta?.alias || [],
    redirect: _404I3w4LJMgaDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _404I3w4LJMgaDMeta?.name ?? "404___it",
    path: _404I3w4LJMgaDMeta?.path ?? "/it/404",
    meta: _404I3w4LJMgaDMeta || {},
    alias: _404I3w4LJMgaDMeta?.alias || [],
    redirect: _404I3w4LJMgaDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _404I3w4LJMgaDMeta?.name ?? "404___de",
    path: _404I3w4LJMgaDMeta?.path ?? "/de/404",
    meta: _404I3w4LJMgaDMeta || {},
    alias: _404I3w4LJMgaDMeta?.alias || [],
    redirect: _404I3w4LJMgaDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _404I3w4LJMgaDMeta?.name ?? "404___es",
    path: _404I3w4LJMgaDMeta?.path ?? "/es/404",
    meta: _404I3w4LJMgaDMeta || {},
    alias: _404I3w4LJMgaDMeta?.alias || [],
    redirect: _404I3w4LJMgaDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _404I3w4LJMgaDMeta?.name ?? "404___es-XL",
    path: _404I3w4LJMgaDMeta?.path ?? "/es-XL/404",
    meta: _404I3w4LJMgaDMeta || {},
    alias: _404I3w4LJMgaDMeta?.alias || [],
    redirect: _404I3w4LJMgaDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _404I3w4LJMgaDMeta?.name ?? "404___pt-BR",
    path: _404I3w4LJMgaDMeta?.path ?? "/pt-BR/404",
    meta: _404I3w4LJMgaDMeta || {},
    alias: _404I3w4LJMgaDMeta?.alias || [],
    redirect: _404I3w4LJMgaDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _5008J1FrBOL17Meta?.name ?? "500___en",
    path: _5008J1FrBOL17Meta?.path ?? "/500",
    meta: _5008J1FrBOL17Meta || {},
    alias: _5008J1FrBOL17Meta?.alias || [],
    redirect: _5008J1FrBOL17Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: _5008J1FrBOL17Meta?.name ?? "500___fr",
    path: _5008J1FrBOL17Meta?.path ?? "/fr/500",
    meta: _5008J1FrBOL17Meta || {},
    alias: _5008J1FrBOL17Meta?.alias || [],
    redirect: _5008J1FrBOL17Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: _5008J1FrBOL17Meta?.name ?? "500___it",
    path: _5008J1FrBOL17Meta?.path ?? "/it/500",
    meta: _5008J1FrBOL17Meta || {},
    alias: _5008J1FrBOL17Meta?.alias || [],
    redirect: _5008J1FrBOL17Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: _5008J1FrBOL17Meta?.name ?? "500___de",
    path: _5008J1FrBOL17Meta?.path ?? "/de/500",
    meta: _5008J1FrBOL17Meta || {},
    alias: _5008J1FrBOL17Meta?.alias || [],
    redirect: _5008J1FrBOL17Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: _5008J1FrBOL17Meta?.name ?? "500___es",
    path: _5008J1FrBOL17Meta?.path ?? "/es/500",
    meta: _5008J1FrBOL17Meta || {},
    alias: _5008J1FrBOL17Meta?.alias || [],
    redirect: _5008J1FrBOL17Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: _5008J1FrBOL17Meta?.name ?? "500___es-XL",
    path: _5008J1FrBOL17Meta?.path ?? "/es-XL/500",
    meta: _5008J1FrBOL17Meta || {},
    alias: _5008J1FrBOL17Meta?.alias || [],
    redirect: _5008J1FrBOL17Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: _5008J1FrBOL17Meta?.name ?? "500___pt-BR",
    path: _5008J1FrBOL17Meta?.path ?? "/pt-BR/500",
    meta: _5008J1FrBOL17Meta || {},
    alias: _5008J1FrBOL17Meta?.alias || [],
    redirect: _5008J1FrBOL17Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: _91post_45slug_93xTQBGZjIZiMeta?.name ?? "blog-postslug___en",
    path: _91post_45slug_93xTQBGZjIZiMeta?.path ?? "/blog/:postslug()",
    meta: _91post_45slug_93xTQBGZjIZiMeta || {},
    alias: _91post_45slug_93xTQBGZjIZiMeta?.alias || [],
    redirect: _91post_45slug_93xTQBGZjIZiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: _91post_45slug_93xTQBGZjIZiMeta?.name ?? "blog-postslug___fr",
    path: _91post_45slug_93xTQBGZjIZiMeta?.path ?? "/fr/blog/:postslug()",
    meta: _91post_45slug_93xTQBGZjIZiMeta || {},
    alias: _91post_45slug_93xTQBGZjIZiMeta?.alias || [],
    redirect: _91post_45slug_93xTQBGZjIZiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: _91post_45slug_93xTQBGZjIZiMeta?.name ?? "blog-postslug___it",
    path: _91post_45slug_93xTQBGZjIZiMeta?.path ?? "/it/blog/:postslug()",
    meta: _91post_45slug_93xTQBGZjIZiMeta || {},
    alias: _91post_45slug_93xTQBGZjIZiMeta?.alias || [],
    redirect: _91post_45slug_93xTQBGZjIZiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: _91post_45slug_93xTQBGZjIZiMeta?.name ?? "blog-postslug___de",
    path: _91post_45slug_93xTQBGZjIZiMeta?.path ?? "/de/blog/:postslug()",
    meta: _91post_45slug_93xTQBGZjIZiMeta || {},
    alias: _91post_45slug_93xTQBGZjIZiMeta?.alias || [],
    redirect: _91post_45slug_93xTQBGZjIZiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: _91post_45slug_93xTQBGZjIZiMeta?.name ?? "blog-postslug___es",
    path: _91post_45slug_93xTQBGZjIZiMeta?.path ?? "/es/blog/:postslug()",
    meta: _91post_45slug_93xTQBGZjIZiMeta || {},
    alias: _91post_45slug_93xTQBGZjIZiMeta?.alias || [],
    redirect: _91post_45slug_93xTQBGZjIZiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: _91post_45slug_93xTQBGZjIZiMeta?.name ?? "blog-postslug___es-XL",
    path: _91post_45slug_93xTQBGZjIZiMeta?.path ?? "/es-XL/blog/:postslug()",
    meta: _91post_45slug_93xTQBGZjIZiMeta || {},
    alias: _91post_45slug_93xTQBGZjIZiMeta?.alias || [],
    redirect: _91post_45slug_93xTQBGZjIZiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: _91post_45slug_93xTQBGZjIZiMeta?.name ?? "blog-postslug___pt-BR",
    path: _91post_45slug_93xTQBGZjIZiMeta?.path ?? "/pt-BR/blog/:postslug()",
    meta: _91post_45slug_93xTQBGZjIZiMeta || {},
    alias: _91post_45slug_93xTQBGZjIZiMeta?.alias || [],
    redirect: _91post_45slug_93xTQBGZjIZiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/[post-slug].vue").then(m => m.default || m)
  },
  {
    name: indexA8cZown3tvMeta?.name ?? "blog___en",
    path: indexA8cZown3tvMeta?.path ?? "/blog",
    meta: indexA8cZown3tvMeta || {},
    alias: indexA8cZown3tvMeta?.alias || [],
    redirect: indexA8cZown3tvMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexA8cZown3tvMeta?.name ?? "blog___fr",
    path: indexA8cZown3tvMeta?.path ?? "/fr/blog",
    meta: indexA8cZown3tvMeta || {},
    alias: indexA8cZown3tvMeta?.alias || [],
    redirect: indexA8cZown3tvMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexA8cZown3tvMeta?.name ?? "blog___it",
    path: indexA8cZown3tvMeta?.path ?? "/it/blog",
    meta: indexA8cZown3tvMeta || {},
    alias: indexA8cZown3tvMeta?.alias || [],
    redirect: indexA8cZown3tvMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexA8cZown3tvMeta?.name ?? "blog___de",
    path: indexA8cZown3tvMeta?.path ?? "/de/blog",
    meta: indexA8cZown3tvMeta || {},
    alias: indexA8cZown3tvMeta?.alias || [],
    redirect: indexA8cZown3tvMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexA8cZown3tvMeta?.name ?? "blog___es",
    path: indexA8cZown3tvMeta?.path ?? "/es/blog",
    meta: indexA8cZown3tvMeta || {},
    alias: indexA8cZown3tvMeta?.alias || [],
    redirect: indexA8cZown3tvMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexA8cZown3tvMeta?.name ?? "blog___es-XL",
    path: indexA8cZown3tvMeta?.path ?? "/es-XL/blog",
    meta: indexA8cZown3tvMeta || {},
    alias: indexA8cZown3tvMeta?.alias || [],
    redirect: indexA8cZown3tvMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexA8cZown3tvMeta?.name ?? "blog___pt-BR",
    path: indexA8cZown3tvMeta?.path ?? "/pt-BR/blog",
    meta: indexA8cZown3tvMeta || {},
    alias: indexA8cZown3tvMeta?.alias || [],
    redirect: indexA8cZown3tvMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.name ?? "champd-up-slam-down-rules___en",
    path: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.path ?? "/champd-up-slam-down-rules",
    meta: champd_45up_45slam_45down_45rulesq5die7ZAddMeta || {},
    alias: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.alias || [],
    redirect: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.name ?? "champd-up-slam-down-rules___fr",
    path: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.path ?? "/fr/champd-up-slam-down-rules",
    meta: champd_45up_45slam_45down_45rulesq5die7ZAddMeta || {},
    alias: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.alias || [],
    redirect: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.name ?? "champd-up-slam-down-rules___it",
    path: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.path ?? "/it/champd-up-slam-down-rules",
    meta: champd_45up_45slam_45down_45rulesq5die7ZAddMeta || {},
    alias: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.alias || [],
    redirect: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.name ?? "champd-up-slam-down-rules___de",
    path: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.path ?? "/de/champd-up-slam-down-rules",
    meta: champd_45up_45slam_45down_45rulesq5die7ZAddMeta || {},
    alias: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.alias || [],
    redirect: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.name ?? "champd-up-slam-down-rules___es",
    path: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.path ?? "/es/champd-up-slam-down-rules",
    meta: champd_45up_45slam_45down_45rulesq5die7ZAddMeta || {},
    alias: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.alias || [],
    redirect: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.name ?? "champd-up-slam-down-rules___es-XL",
    path: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.path ?? "/es-XL/champd-up-slam-down-rules",
    meta: champd_45up_45slam_45down_45rulesq5die7ZAddMeta || {},
    alias: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.alias || [],
    redirect: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.name ?? "champd-up-slam-down-rules___pt-BR",
    path: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.path ?? "/pt-BR/champd-up-slam-down-rules",
    meta: champd_45up_45slam_45down_45rulesq5die7ZAddMeta || {},
    alias: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.alias || [],
    redirect: champd_45up_45slam_45down_45rulesq5die7ZAddMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/champd-up-slam-down-rules.vue").then(m => m.default || m)
  },
  {
    name: companyTeIkuZ5QSYMeta?.name ?? "company___en",
    path: companyTeIkuZ5QSYMeta?.path ?? "/company",
    meta: companyTeIkuZ5QSYMeta || {},
    alias: companyTeIkuZ5QSYMeta?.alias || [],
    redirect: companyTeIkuZ5QSYMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: companyTeIkuZ5QSYMeta?.name ?? "company___fr",
    path: companyTeIkuZ5QSYMeta?.path ?? "/fr/company",
    meta: companyTeIkuZ5QSYMeta || {},
    alias: companyTeIkuZ5QSYMeta?.alias || [],
    redirect: companyTeIkuZ5QSYMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: companyTeIkuZ5QSYMeta?.name ?? "company___it",
    path: companyTeIkuZ5QSYMeta?.path ?? "/it/company",
    meta: companyTeIkuZ5QSYMeta || {},
    alias: companyTeIkuZ5QSYMeta?.alias || [],
    redirect: companyTeIkuZ5QSYMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: companyTeIkuZ5QSYMeta?.name ?? "company___de",
    path: companyTeIkuZ5QSYMeta?.path ?? "/de/company",
    meta: companyTeIkuZ5QSYMeta || {},
    alias: companyTeIkuZ5QSYMeta?.alias || [],
    redirect: companyTeIkuZ5QSYMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: companyTeIkuZ5QSYMeta?.name ?? "company___es",
    path: companyTeIkuZ5QSYMeta?.path ?? "/es/company",
    meta: companyTeIkuZ5QSYMeta || {},
    alias: companyTeIkuZ5QSYMeta?.alias || [],
    redirect: companyTeIkuZ5QSYMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: companyTeIkuZ5QSYMeta?.name ?? "company___es-XL",
    path: companyTeIkuZ5QSYMeta?.path ?? "/es-XL/company",
    meta: companyTeIkuZ5QSYMeta || {},
    alias: companyTeIkuZ5QSYMeta?.alias || [],
    redirect: companyTeIkuZ5QSYMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: companyTeIkuZ5QSYMeta?.name ?? "company___pt-BR",
    path: companyTeIkuZ5QSYMeta?.path ?? "/pt-BR/company",
    meta: companyTeIkuZ5QSYMeta || {},
    alias: companyTeIkuZ5QSYMeta?.alias || [],
    redirect: companyTeIkuZ5QSYMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: cmsjXwgvXJSjFMeta?.name ?? "debug-cms___en",
    path: cmsjXwgvXJSjFMeta?.path ?? "/debug/cms",
    meta: cmsjXwgvXJSjFMeta || {},
    alias: cmsjXwgvXJSjFMeta?.alias || [],
    redirect: cmsjXwgvXJSjFMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: cmsjXwgvXJSjFMeta?.name ?? "debug-cms___fr",
    path: cmsjXwgvXJSjFMeta?.path ?? "/fr/debug/cms",
    meta: cmsjXwgvXJSjFMeta || {},
    alias: cmsjXwgvXJSjFMeta?.alias || [],
    redirect: cmsjXwgvXJSjFMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: cmsjXwgvXJSjFMeta?.name ?? "debug-cms___it",
    path: cmsjXwgvXJSjFMeta?.path ?? "/it/debug/cms",
    meta: cmsjXwgvXJSjFMeta || {},
    alias: cmsjXwgvXJSjFMeta?.alias || [],
    redirect: cmsjXwgvXJSjFMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: cmsjXwgvXJSjFMeta?.name ?? "debug-cms___de",
    path: cmsjXwgvXJSjFMeta?.path ?? "/de/debug/cms",
    meta: cmsjXwgvXJSjFMeta || {},
    alias: cmsjXwgvXJSjFMeta?.alias || [],
    redirect: cmsjXwgvXJSjFMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: cmsjXwgvXJSjFMeta?.name ?? "debug-cms___es",
    path: cmsjXwgvXJSjFMeta?.path ?? "/es/debug/cms",
    meta: cmsjXwgvXJSjFMeta || {},
    alias: cmsjXwgvXJSjFMeta?.alias || [],
    redirect: cmsjXwgvXJSjFMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: cmsjXwgvXJSjFMeta?.name ?? "debug-cms___es-XL",
    path: cmsjXwgvXJSjFMeta?.path ?? "/es-XL/debug/cms",
    meta: cmsjXwgvXJSjFMeta || {},
    alias: cmsjXwgvXJSjFMeta?.alias || [],
    redirect: cmsjXwgvXJSjFMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: cmsjXwgvXJSjFMeta?.name ?? "debug-cms___pt-BR",
    path: cmsjXwgvXJSjFMeta?.path ?? "/pt-BR/debug/cms",
    meta: cmsjXwgvXJSjFMeta || {},
    alias: cmsjXwgvXJSjFMeta?.alias || [],
    redirect: cmsjXwgvXJSjFMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/cms.vue").then(m => m.default || m)
  },
  {
    name: env9FMLxh3JRZMeta?.name ?? "debug-env___en",
    path: env9FMLxh3JRZMeta?.path ?? "/debug/env",
    meta: env9FMLxh3JRZMeta || {},
    alias: env9FMLxh3JRZMeta?.alias || [],
    redirect: env9FMLxh3JRZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: env9FMLxh3JRZMeta?.name ?? "debug-env___fr",
    path: env9FMLxh3JRZMeta?.path ?? "/fr/debug/env",
    meta: env9FMLxh3JRZMeta || {},
    alias: env9FMLxh3JRZMeta?.alias || [],
    redirect: env9FMLxh3JRZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: env9FMLxh3JRZMeta?.name ?? "debug-env___it",
    path: env9FMLxh3JRZMeta?.path ?? "/it/debug/env",
    meta: env9FMLxh3JRZMeta || {},
    alias: env9FMLxh3JRZMeta?.alias || [],
    redirect: env9FMLxh3JRZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: env9FMLxh3JRZMeta?.name ?? "debug-env___de",
    path: env9FMLxh3JRZMeta?.path ?? "/de/debug/env",
    meta: env9FMLxh3JRZMeta || {},
    alias: env9FMLxh3JRZMeta?.alias || [],
    redirect: env9FMLxh3JRZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: env9FMLxh3JRZMeta?.name ?? "debug-env___es",
    path: env9FMLxh3JRZMeta?.path ?? "/es/debug/env",
    meta: env9FMLxh3JRZMeta || {},
    alias: env9FMLxh3JRZMeta?.alias || [],
    redirect: env9FMLxh3JRZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: env9FMLxh3JRZMeta?.name ?? "debug-env___es-XL",
    path: env9FMLxh3JRZMeta?.path ?? "/es-XL/debug/env",
    meta: env9FMLxh3JRZMeta || {},
    alias: env9FMLxh3JRZMeta?.alias || [],
    redirect: env9FMLxh3JRZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: env9FMLxh3JRZMeta?.name ?? "debug-env___pt-BR",
    path: env9FMLxh3JRZMeta?.path ?? "/pt-BR/debug/env",
    meta: env9FMLxh3JRZMeta || {},
    alias: env9FMLxh3JRZMeta?.alias || [],
    redirect: env9FMLxh3JRZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/debug/env.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.name ?? "docs-docpath___en",
    path: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.path ?? "/docs/:docpath(.*)*",
    meta: _91_46_46_46doc_45path_93bnszFk4RZLMeta || {},
    alias: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.alias || [],
    redirect: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.name ?? "docs-docpath___fr",
    path: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.path ?? "/fr/docs/:docpath(.*)*",
    meta: _91_46_46_46doc_45path_93bnszFk4RZLMeta || {},
    alias: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.alias || [],
    redirect: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.name ?? "docs-docpath___it",
    path: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.path ?? "/it/docs/:docpath(.*)*",
    meta: _91_46_46_46doc_45path_93bnszFk4RZLMeta || {},
    alias: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.alias || [],
    redirect: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.name ?? "docs-docpath___de",
    path: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.path ?? "/de/docs/:docpath(.*)*",
    meta: _91_46_46_46doc_45path_93bnszFk4RZLMeta || {},
    alias: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.alias || [],
    redirect: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.name ?? "docs-docpath___es",
    path: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.path ?? "/es/docs/:docpath(.*)*",
    meta: _91_46_46_46doc_45path_93bnszFk4RZLMeta || {},
    alias: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.alias || [],
    redirect: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.name ?? "docs-docpath___es-XL",
    path: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.path ?? "/es-XL/docs/:docpath(.*)*",
    meta: _91_46_46_46doc_45path_93bnszFk4RZLMeta || {},
    alias: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.alias || [],
    redirect: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.name ?? "docs-docpath___pt-BR",
    path: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.path ?? "/pt-BR/docs/:docpath(.*)*",
    meta: _91_46_46_46doc_45path_93bnszFk4RZLMeta || {},
    alias: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.alias || [],
    redirect: _91_46_46_46doc_45path_93bnszFk4RZLMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/[...doc-path].vue").then(m => m.default || m)
  },
  {
    name: indexrwyhvVPykZMeta?.name ?? "docs___en",
    path: indexrwyhvVPykZMeta?.path ?? "/docs",
    meta: indexrwyhvVPykZMeta || {},
    alias: indexrwyhvVPykZMeta?.alias || [],
    redirect: indexrwyhvVPykZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: indexrwyhvVPykZMeta?.name ?? "docs___fr",
    path: indexrwyhvVPykZMeta?.path ?? "/fr/docs",
    meta: indexrwyhvVPykZMeta || {},
    alias: indexrwyhvVPykZMeta?.alias || [],
    redirect: indexrwyhvVPykZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: indexrwyhvVPykZMeta?.name ?? "docs___it",
    path: indexrwyhvVPykZMeta?.path ?? "/it/docs",
    meta: indexrwyhvVPykZMeta || {},
    alias: indexrwyhvVPykZMeta?.alias || [],
    redirect: indexrwyhvVPykZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: indexrwyhvVPykZMeta?.name ?? "docs___de",
    path: indexrwyhvVPykZMeta?.path ?? "/de/docs",
    meta: indexrwyhvVPykZMeta || {},
    alias: indexrwyhvVPykZMeta?.alias || [],
    redirect: indexrwyhvVPykZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: indexrwyhvVPykZMeta?.name ?? "docs___es",
    path: indexrwyhvVPykZMeta?.path ?? "/es/docs",
    meta: indexrwyhvVPykZMeta || {},
    alias: indexrwyhvVPykZMeta?.alias || [],
    redirect: indexrwyhvVPykZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: indexrwyhvVPykZMeta?.name ?? "docs___es-XL",
    path: indexrwyhvVPykZMeta?.path ?? "/es-XL/docs",
    meta: indexrwyhvVPykZMeta || {},
    alias: indexrwyhvVPykZMeta?.alias || [],
    redirect: indexrwyhvVPykZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: indexrwyhvVPykZMeta?.name ?? "docs___pt-BR",
    path: indexrwyhvVPykZMeta?.path ?? "/pt-BR/docs",
    meta: indexrwyhvVPykZMeta || {},
    alias: indexrwyhvVPykZMeta?.alias || [],
    redirect: indexrwyhvVPykZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: _91game_45type_93Jp7uBiS5BVMeta?.name ?? "game-type-gametype___en",
    path: _91game_45type_93Jp7uBiS5BVMeta?.path ?? "/game-type/:gametype()",
    meta: _91game_45type_93Jp7uBiS5BVMeta || {},
    alias: _91game_45type_93Jp7uBiS5BVMeta?.alias || [],
    redirect: _91game_45type_93Jp7uBiS5BVMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[game-type].vue").then(m => m.default || m)
  },
  {
    name: _91game_45type_93Jp7uBiS5BVMeta?.name ?? "game-type-gametype___fr",
    path: _91game_45type_93Jp7uBiS5BVMeta?.path ?? "/fr/game-type/:gametype()",
    meta: _91game_45type_93Jp7uBiS5BVMeta || {},
    alias: _91game_45type_93Jp7uBiS5BVMeta?.alias || [],
    redirect: _91game_45type_93Jp7uBiS5BVMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[game-type].vue").then(m => m.default || m)
  },
  {
    name: _91game_45type_93Jp7uBiS5BVMeta?.name ?? "game-type-gametype___it",
    path: _91game_45type_93Jp7uBiS5BVMeta?.path ?? "/it/game-type/:gametype()",
    meta: _91game_45type_93Jp7uBiS5BVMeta || {},
    alias: _91game_45type_93Jp7uBiS5BVMeta?.alias || [],
    redirect: _91game_45type_93Jp7uBiS5BVMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[game-type].vue").then(m => m.default || m)
  },
  {
    name: _91game_45type_93Jp7uBiS5BVMeta?.name ?? "game-type-gametype___de",
    path: _91game_45type_93Jp7uBiS5BVMeta?.path ?? "/de/game-type/:gametype()",
    meta: _91game_45type_93Jp7uBiS5BVMeta || {},
    alias: _91game_45type_93Jp7uBiS5BVMeta?.alias || [],
    redirect: _91game_45type_93Jp7uBiS5BVMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[game-type].vue").then(m => m.default || m)
  },
  {
    name: _91game_45type_93Jp7uBiS5BVMeta?.name ?? "game-type-gametype___es",
    path: _91game_45type_93Jp7uBiS5BVMeta?.path ?? "/es/game-type/:gametype()",
    meta: _91game_45type_93Jp7uBiS5BVMeta || {},
    alias: _91game_45type_93Jp7uBiS5BVMeta?.alias || [],
    redirect: _91game_45type_93Jp7uBiS5BVMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[game-type].vue").then(m => m.default || m)
  },
  {
    name: _91game_45type_93Jp7uBiS5BVMeta?.name ?? "game-type-gametype___es-XL",
    path: _91game_45type_93Jp7uBiS5BVMeta?.path ?? "/es-XL/game-type/:gametype()",
    meta: _91game_45type_93Jp7uBiS5BVMeta || {},
    alias: _91game_45type_93Jp7uBiS5BVMeta?.alias || [],
    redirect: _91game_45type_93Jp7uBiS5BVMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[game-type].vue").then(m => m.default || m)
  },
  {
    name: _91game_45type_93Jp7uBiS5BVMeta?.name ?? "game-type-gametype___pt-BR",
    path: _91game_45type_93Jp7uBiS5BVMeta?.path ?? "/pt-BR/game-type/:gametype()",
    meta: _91game_45type_93Jp7uBiS5BVMeta || {},
    alias: _91game_45type_93Jp7uBiS5BVMeta?.alias || [],
    redirect: _91game_45type_93Jp7uBiS5BVMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/[game-type].vue").then(m => m.default || m)
  },
  {
    name: indexht5dXWRn4dMeta?.name ?? "game-type___en",
    path: indexht5dXWRn4dMeta?.path ?? "/game-type",
    meta: indexht5dXWRn4dMeta || {},
    alias: indexht5dXWRn4dMeta?.alias || [],
    redirect: indexht5dXWRn4dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: indexht5dXWRn4dMeta?.name ?? "game-type___fr",
    path: indexht5dXWRn4dMeta?.path ?? "/fr/game-type",
    meta: indexht5dXWRn4dMeta || {},
    alias: indexht5dXWRn4dMeta?.alias || [],
    redirect: indexht5dXWRn4dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: indexht5dXWRn4dMeta?.name ?? "game-type___it",
    path: indexht5dXWRn4dMeta?.path ?? "/it/game-type",
    meta: indexht5dXWRn4dMeta || {},
    alias: indexht5dXWRn4dMeta?.alias || [],
    redirect: indexht5dXWRn4dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: indexht5dXWRn4dMeta?.name ?? "game-type___de",
    path: indexht5dXWRn4dMeta?.path ?? "/de/game-type",
    meta: indexht5dXWRn4dMeta || {},
    alias: indexht5dXWRn4dMeta?.alias || [],
    redirect: indexht5dXWRn4dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: indexht5dXWRn4dMeta?.name ?? "game-type___es",
    path: indexht5dXWRn4dMeta?.path ?? "/es/game-type",
    meta: indexht5dXWRn4dMeta || {},
    alias: indexht5dXWRn4dMeta?.alias || [],
    redirect: indexht5dXWRn4dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: indexht5dXWRn4dMeta?.name ?? "game-type___es-XL",
    path: indexht5dXWRn4dMeta?.path ?? "/es-XL/game-type",
    meta: indexht5dXWRn4dMeta || {},
    alias: indexht5dXWRn4dMeta?.alias || [],
    redirect: indexht5dXWRn4dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: indexht5dXWRn4dMeta?.name ?? "game-type___pt-BR",
    path: indexht5dXWRn4dMeta?.path ?? "/pt-BR/game-type",
    meta: indexht5dXWRn4dMeta || {},
    alias: indexht5dXWRn4dMeta?.alias || [],
    redirect: indexht5dXWRn4dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/game-type/index.vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-gameslug___en",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/games/:gameslug()",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-gameslug___fr",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/fr/games/:gameslug()",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-gameslug___it",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/it/games/:gameslug()",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-gameslug___de",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/de/games/:gameslug()",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-gameslug___es",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/es/games/:gameslug()",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-gameslug___es-XL",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/es-XL/games/:gameslug()",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-gameslug___pt-BR",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/pt-BR/games/:gameslug()",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: _91bundle_45slug_93tH9UZhZSF3Meta?.name ?? "games-bundles-bundleslug___en",
    path: _91bundle_45slug_93tH9UZhZSF3Meta?.path ?? "/games/bundles/:bundleslug()",
    meta: _91bundle_45slug_93tH9UZhZSF3Meta || {},
    alias: _91bundle_45slug_93tH9UZhZSF3Meta?.alias || [],
    redirect: _91bundle_45slug_93tH9UZhZSF3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: _91bundle_45slug_93tH9UZhZSF3Meta?.name ?? "games-bundles-bundleslug___fr",
    path: _91bundle_45slug_93tH9UZhZSF3Meta?.path ?? "/fr/games/bundles/:bundleslug()",
    meta: _91bundle_45slug_93tH9UZhZSF3Meta || {},
    alias: _91bundle_45slug_93tH9UZhZSF3Meta?.alias || [],
    redirect: _91bundle_45slug_93tH9UZhZSF3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: _91bundle_45slug_93tH9UZhZSF3Meta?.name ?? "games-bundles-bundleslug___it",
    path: _91bundle_45slug_93tH9UZhZSF3Meta?.path ?? "/it/games/bundles/:bundleslug()",
    meta: _91bundle_45slug_93tH9UZhZSF3Meta || {},
    alias: _91bundle_45slug_93tH9UZhZSF3Meta?.alias || [],
    redirect: _91bundle_45slug_93tH9UZhZSF3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: _91bundle_45slug_93tH9UZhZSF3Meta?.name ?? "games-bundles-bundleslug___de",
    path: _91bundle_45slug_93tH9UZhZSF3Meta?.path ?? "/de/games/bundles/:bundleslug()",
    meta: _91bundle_45slug_93tH9UZhZSF3Meta || {},
    alias: _91bundle_45slug_93tH9UZhZSF3Meta?.alias || [],
    redirect: _91bundle_45slug_93tH9UZhZSF3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: _91bundle_45slug_93tH9UZhZSF3Meta?.name ?? "games-bundles-bundleslug___es",
    path: _91bundle_45slug_93tH9UZhZSF3Meta?.path ?? "/es/games/bundles/:bundleslug()",
    meta: _91bundle_45slug_93tH9UZhZSF3Meta || {},
    alias: _91bundle_45slug_93tH9UZhZSF3Meta?.alias || [],
    redirect: _91bundle_45slug_93tH9UZhZSF3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: _91bundle_45slug_93tH9UZhZSF3Meta?.name ?? "games-bundles-bundleslug___es-XL",
    path: _91bundle_45slug_93tH9UZhZSF3Meta?.path ?? "/es-XL/games/bundles/:bundleslug()",
    meta: _91bundle_45slug_93tH9UZhZSF3Meta || {},
    alias: _91bundle_45slug_93tH9UZhZSF3Meta?.alias || [],
    redirect: _91bundle_45slug_93tH9UZhZSF3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: _91bundle_45slug_93tH9UZhZSF3Meta?.name ?? "games-bundles-bundleslug___pt-BR",
    path: _91bundle_45slug_93tH9UZhZSF3Meta?.path ?? "/pt-BR/games/bundles/:bundleslug()",
    meta: _91bundle_45slug_93tH9UZhZSF3Meta || {},
    alias: _91bundle_45slug_93tH9UZhZSF3Meta?.alias || [],
    redirect: _91bundle_45slug_93tH9UZhZSF3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/[bundle-slug].vue").then(m => m.default || m)
  },
  {
    name: indexfiih8saSNiMeta?.name ?? "games-bundles___en",
    path: indexfiih8saSNiMeta?.path ?? "/games/bundles",
    meta: indexfiih8saSNiMeta || {},
    alias: indexfiih8saSNiMeta?.alias || [],
    redirect: indexfiih8saSNiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/index.vue").then(m => m.default || m)
  },
  {
    name: indexfiih8saSNiMeta?.name ?? "games-bundles___fr",
    path: indexfiih8saSNiMeta?.path ?? "/fr/games/bundles",
    meta: indexfiih8saSNiMeta || {},
    alias: indexfiih8saSNiMeta?.alias || [],
    redirect: indexfiih8saSNiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/index.vue").then(m => m.default || m)
  },
  {
    name: indexfiih8saSNiMeta?.name ?? "games-bundles___it",
    path: indexfiih8saSNiMeta?.path ?? "/it/games/bundles",
    meta: indexfiih8saSNiMeta || {},
    alias: indexfiih8saSNiMeta?.alias || [],
    redirect: indexfiih8saSNiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/index.vue").then(m => m.default || m)
  },
  {
    name: indexfiih8saSNiMeta?.name ?? "games-bundles___de",
    path: indexfiih8saSNiMeta?.path ?? "/de/games/bundles",
    meta: indexfiih8saSNiMeta || {},
    alias: indexfiih8saSNiMeta?.alias || [],
    redirect: indexfiih8saSNiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/index.vue").then(m => m.default || m)
  },
  {
    name: indexfiih8saSNiMeta?.name ?? "games-bundles___es",
    path: indexfiih8saSNiMeta?.path ?? "/es/games/bundles",
    meta: indexfiih8saSNiMeta || {},
    alias: indexfiih8saSNiMeta?.alias || [],
    redirect: indexfiih8saSNiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/index.vue").then(m => m.default || m)
  },
  {
    name: indexfiih8saSNiMeta?.name ?? "games-bundles___es-XL",
    path: indexfiih8saSNiMeta?.path ?? "/es-XL/games/bundles",
    meta: indexfiih8saSNiMeta || {},
    alias: indexfiih8saSNiMeta?.alias || [],
    redirect: indexfiih8saSNiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/index.vue").then(m => m.default || m)
  },
  {
    name: indexfiih8saSNiMeta?.name ?? "games-bundles___pt-BR",
    path: indexfiih8saSNiMeta?.path ?? "/pt-BR/games/bundles",
    meta: indexfiih8saSNiMeta || {},
    alias: indexfiih8saSNiMeta?.alias || [],
    redirect: indexfiih8saSNiMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/bundles/index.vue").then(m => m.default || m)
  },
  {
    name: indexPMdrJIgED0Meta?.name ?? "games___en",
    path: indexPMdrJIgED0Meta?.path ?? "/games",
    meta: indexPMdrJIgED0Meta || {},
    alias: indexPMdrJIgED0Meta?.alias || [],
    redirect: indexPMdrJIgED0Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: indexPMdrJIgED0Meta?.name ?? "games___fr",
    path: indexPMdrJIgED0Meta?.path ?? "/fr/games",
    meta: indexPMdrJIgED0Meta || {},
    alias: indexPMdrJIgED0Meta?.alias || [],
    redirect: indexPMdrJIgED0Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: indexPMdrJIgED0Meta?.name ?? "games___it",
    path: indexPMdrJIgED0Meta?.path ?? "/it/games",
    meta: indexPMdrJIgED0Meta || {},
    alias: indexPMdrJIgED0Meta?.alias || [],
    redirect: indexPMdrJIgED0Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: indexPMdrJIgED0Meta?.name ?? "games___de",
    path: indexPMdrJIgED0Meta?.path ?? "/de/games",
    meta: indexPMdrJIgED0Meta || {},
    alias: indexPMdrJIgED0Meta?.alias || [],
    redirect: indexPMdrJIgED0Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: indexPMdrJIgED0Meta?.name ?? "games___es",
    path: indexPMdrJIgED0Meta?.path ?? "/es/games",
    meta: indexPMdrJIgED0Meta || {},
    alias: indexPMdrJIgED0Meta?.alias || [],
    redirect: indexPMdrJIgED0Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: indexPMdrJIgED0Meta?.name ?? "games___es-XL",
    path: indexPMdrJIgED0Meta?.path ?? "/es-XL/games",
    meta: indexPMdrJIgED0Meta || {},
    alias: indexPMdrJIgED0Meta?.alias || [],
    redirect: indexPMdrJIgED0Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: indexPMdrJIgED0Meta?.name ?? "games___pt-BR",
    path: indexPMdrJIgED0Meta?.path ?? "/pt-BR/games",
    meta: indexPMdrJIgED0Meta || {},
    alias: indexPMdrJIgED0Meta?.alias || [],
    redirect: indexPMdrJIgED0Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: _91pack_45slug_93p9ixEkCerOMeta?.name ?? "games-packs-packslug___en",
    path: _91pack_45slug_93p9ixEkCerOMeta?.path ?? "/games/packs/:packslug()",
    meta: _91pack_45slug_93p9ixEkCerOMeta || {},
    alias: _91pack_45slug_93p9ixEkCerOMeta?.alias || [],
    redirect: _91pack_45slug_93p9ixEkCerOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: _91pack_45slug_93p9ixEkCerOMeta?.name ?? "games-packs-packslug___fr",
    path: _91pack_45slug_93p9ixEkCerOMeta?.path ?? "/fr/games/packs/:packslug()",
    meta: _91pack_45slug_93p9ixEkCerOMeta || {},
    alias: _91pack_45slug_93p9ixEkCerOMeta?.alias || [],
    redirect: _91pack_45slug_93p9ixEkCerOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: _91pack_45slug_93p9ixEkCerOMeta?.name ?? "games-packs-packslug___it",
    path: _91pack_45slug_93p9ixEkCerOMeta?.path ?? "/it/games/packs/:packslug()",
    meta: _91pack_45slug_93p9ixEkCerOMeta || {},
    alias: _91pack_45slug_93p9ixEkCerOMeta?.alias || [],
    redirect: _91pack_45slug_93p9ixEkCerOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: _91pack_45slug_93p9ixEkCerOMeta?.name ?? "games-packs-packslug___de",
    path: _91pack_45slug_93p9ixEkCerOMeta?.path ?? "/de/games/packs/:packslug()",
    meta: _91pack_45slug_93p9ixEkCerOMeta || {},
    alias: _91pack_45slug_93p9ixEkCerOMeta?.alias || [],
    redirect: _91pack_45slug_93p9ixEkCerOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: _91pack_45slug_93p9ixEkCerOMeta?.name ?? "games-packs-packslug___es",
    path: _91pack_45slug_93p9ixEkCerOMeta?.path ?? "/es/games/packs/:packslug()",
    meta: _91pack_45slug_93p9ixEkCerOMeta || {},
    alias: _91pack_45slug_93p9ixEkCerOMeta?.alias || [],
    redirect: _91pack_45slug_93p9ixEkCerOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: _91pack_45slug_93p9ixEkCerOMeta?.name ?? "games-packs-packslug___es-XL",
    path: _91pack_45slug_93p9ixEkCerOMeta?.path ?? "/es-XL/games/packs/:packslug()",
    meta: _91pack_45slug_93p9ixEkCerOMeta || {},
    alias: _91pack_45slug_93p9ixEkCerOMeta?.alias || [],
    redirect: _91pack_45slug_93p9ixEkCerOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: _91pack_45slug_93p9ixEkCerOMeta?.name ?? "games-packs-packslug___pt-BR",
    path: _91pack_45slug_93p9ixEkCerOMeta?.path ?? "/pt-BR/games/packs/:packslug()",
    meta: _91pack_45slug_93p9ixEkCerOMeta || {},
    alias: _91pack_45slug_93p9ixEkCerOMeta?.alias || [],
    redirect: _91pack_45slug_93p9ixEkCerOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/[pack-slug].vue").then(m => m.default || m)
  },
  {
    name: indexhJrPnC150BMeta?.name ?? "games-packs___en",
    path: indexhJrPnC150BMeta?.path ?? "/games/packs",
    meta: indexhJrPnC150BMeta || {},
    alias: indexhJrPnC150BMeta?.alias || [],
    redirect: indexhJrPnC150BMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: indexhJrPnC150BMeta?.name ?? "games-packs___fr",
    path: indexhJrPnC150BMeta?.path ?? "/fr/games/packs",
    meta: indexhJrPnC150BMeta || {},
    alias: indexhJrPnC150BMeta?.alias || [],
    redirect: indexhJrPnC150BMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: indexhJrPnC150BMeta?.name ?? "games-packs___it",
    path: indexhJrPnC150BMeta?.path ?? "/it/games/packs",
    meta: indexhJrPnC150BMeta || {},
    alias: indexhJrPnC150BMeta?.alias || [],
    redirect: indexhJrPnC150BMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: indexhJrPnC150BMeta?.name ?? "games-packs___de",
    path: indexhJrPnC150BMeta?.path ?? "/de/games/packs",
    meta: indexhJrPnC150BMeta || {},
    alias: indexhJrPnC150BMeta?.alias || [],
    redirect: indexhJrPnC150BMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: indexhJrPnC150BMeta?.name ?? "games-packs___es",
    path: indexhJrPnC150BMeta?.path ?? "/es/games/packs",
    meta: indexhJrPnC150BMeta || {},
    alias: indexhJrPnC150BMeta?.alias || [],
    redirect: indexhJrPnC150BMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: indexhJrPnC150BMeta?.name ?? "games-packs___es-XL",
    path: indexhJrPnC150BMeta?.path ?? "/es-XL/games/packs",
    meta: indexhJrPnC150BMeta || {},
    alias: indexhJrPnC150BMeta?.alias || [],
    redirect: indexhJrPnC150BMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: indexhJrPnC150BMeta?.name ?? "games-packs___pt-BR",
    path: indexhJrPnC150BMeta?.path ?? "/pt-BR/games/packs",
    meta: indexhJrPnC150BMeta || {},
    alias: indexhJrPnC150BMeta?.alias || [],
    redirect: indexhJrPnC150BMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/packs/index.vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift___en",
    path: giftmbcvizTL7nMeta?.path ?? "/gift",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift___fr",
    path: giftmbcvizTL7nMeta?.path ?? "/fr/gift",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift___it",
    path: giftmbcvizTL7nMeta?.path ?? "/it/gift",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift___de",
    path: giftmbcvizTL7nMeta?.path ?? "/de/gift",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift___es",
    path: giftmbcvizTL7nMeta?.path ?? "/es/gift",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift___es-XL",
    path: giftmbcvizTL7nMeta?.path ?? "/es-XL/gift",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift___pt-BR",
    path: giftmbcvizTL7nMeta?.path ?? "/pt-BR/gift",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45playVVRUHfbLkKMeta?.name ?? "how-to-play___en",
    path: how_45to_45playVVRUHfbLkKMeta?.path ?? "/how-to-play",
    meta: how_45to_45playVVRUHfbLkKMeta || {},
    alias: how_45to_45playVVRUHfbLkKMeta?.alias || [],
    redirect: how_45to_45playVVRUHfbLkKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45playVVRUHfbLkKMeta?.name ?? "how-to-play___fr",
    path: how_45to_45playVVRUHfbLkKMeta?.path ?? "/fr/how-to-play",
    meta: how_45to_45playVVRUHfbLkKMeta || {},
    alias: how_45to_45playVVRUHfbLkKMeta?.alias || [],
    redirect: how_45to_45playVVRUHfbLkKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45playVVRUHfbLkKMeta?.name ?? "how-to-play___it",
    path: how_45to_45playVVRUHfbLkKMeta?.path ?? "/it/how-to-play",
    meta: how_45to_45playVVRUHfbLkKMeta || {},
    alias: how_45to_45playVVRUHfbLkKMeta?.alias || [],
    redirect: how_45to_45playVVRUHfbLkKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45playVVRUHfbLkKMeta?.name ?? "how-to-play___de",
    path: how_45to_45playVVRUHfbLkKMeta?.path ?? "/de/how-to-play",
    meta: how_45to_45playVVRUHfbLkKMeta || {},
    alias: how_45to_45playVVRUHfbLkKMeta?.alias || [],
    redirect: how_45to_45playVVRUHfbLkKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45playVVRUHfbLkKMeta?.name ?? "how-to-play___es",
    path: how_45to_45playVVRUHfbLkKMeta?.path ?? "/es/how-to-play",
    meta: how_45to_45playVVRUHfbLkKMeta || {},
    alias: how_45to_45playVVRUHfbLkKMeta?.alias || [],
    redirect: how_45to_45playVVRUHfbLkKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45playVVRUHfbLkKMeta?.name ?? "how-to-play___es-XL",
    path: how_45to_45playVVRUHfbLkKMeta?.path ?? "/es-XL/how-to-play",
    meta: how_45to_45playVVRUHfbLkKMeta || {},
    alias: how_45to_45playVVRUHfbLkKMeta?.alias || [],
    redirect: how_45to_45playVVRUHfbLkKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45playVVRUHfbLkKMeta?.name ?? "how-to-play___pt-BR",
    path: how_45to_45playVVRUHfbLkKMeta?.path ?? "/pt-BR/how-to-play",
    meta: how_45to_45playVVRUHfbLkKMeta || {},
    alias: how_45to_45playVVRUHfbLkKMeta?.alias || [],
    redirect: how_45to_45playVVRUHfbLkKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/how-to-play.vue").then(m => m.default || m)
  },
  {
    name: indexuRmSTRB6icMeta?.name ?? "index___en",
    path: indexuRmSTRB6icMeta?.path ?? "/",
    meta: indexuRmSTRB6icMeta || {},
    alias: indexuRmSTRB6icMeta?.alias || [],
    redirect: indexuRmSTRB6icMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexuRmSTRB6icMeta?.name ?? "index___fr",
    path: indexuRmSTRB6icMeta?.path ?? "/fr",
    meta: indexuRmSTRB6icMeta || {},
    alias: indexuRmSTRB6icMeta?.alias || [],
    redirect: indexuRmSTRB6icMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexuRmSTRB6icMeta?.name ?? "index___it",
    path: indexuRmSTRB6icMeta?.path ?? "/it",
    meta: indexuRmSTRB6icMeta || {},
    alias: indexuRmSTRB6icMeta?.alias || [],
    redirect: indexuRmSTRB6icMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexuRmSTRB6icMeta?.name ?? "index___de",
    path: indexuRmSTRB6icMeta?.path ?? "/de",
    meta: indexuRmSTRB6icMeta || {},
    alias: indexuRmSTRB6icMeta?.alias || [],
    redirect: indexuRmSTRB6icMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexuRmSTRB6icMeta?.name ?? "index___es",
    path: indexuRmSTRB6icMeta?.path ?? "/es",
    meta: indexuRmSTRB6icMeta || {},
    alias: indexuRmSTRB6icMeta?.alias || [],
    redirect: indexuRmSTRB6icMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexuRmSTRB6icMeta?.name ?? "index___es-XL",
    path: indexuRmSTRB6icMeta?.path ?? "/es-XL",
    meta: indexuRmSTRB6icMeta || {},
    alias: indexuRmSTRB6icMeta?.alias || [],
    redirect: indexuRmSTRB6icMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexuRmSTRB6icMeta?.name ?? "index___pt-BR",
    path: indexuRmSTRB6icMeta?.path ?? "/pt-BR",
    meta: indexuRmSTRB6icMeta || {},
    alias: indexuRmSTRB6icMeta?.alias || [],
    redirect: indexuRmSTRB6icMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45at_45workHO1zr6A9GjMeta?.name ?? "jackbox-at-work___en",
    path: jackbox_45at_45workHO1zr6A9GjMeta?.path ?? "/jackbox-at-work",
    meta: jackbox_45at_45workHO1zr6A9GjMeta || {},
    alias: jackbox_45at_45workHO1zr6A9GjMeta?.alias || [],
    redirect: jackbox_45at_45workHO1zr6A9GjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45at_45workHO1zr6A9GjMeta?.name ?? "jackbox-at-work___fr",
    path: jackbox_45at_45workHO1zr6A9GjMeta?.path ?? "/fr/jackbox-at-work",
    meta: jackbox_45at_45workHO1zr6A9GjMeta || {},
    alias: jackbox_45at_45workHO1zr6A9GjMeta?.alias || [],
    redirect: jackbox_45at_45workHO1zr6A9GjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45at_45workHO1zr6A9GjMeta?.name ?? "jackbox-at-work___it",
    path: jackbox_45at_45workHO1zr6A9GjMeta?.path ?? "/it/jackbox-at-work",
    meta: jackbox_45at_45workHO1zr6A9GjMeta || {},
    alias: jackbox_45at_45workHO1zr6A9GjMeta?.alias || [],
    redirect: jackbox_45at_45workHO1zr6A9GjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45at_45workHO1zr6A9GjMeta?.name ?? "jackbox-at-work___de",
    path: jackbox_45at_45workHO1zr6A9GjMeta?.path ?? "/de/jackbox-at-work",
    meta: jackbox_45at_45workHO1zr6A9GjMeta || {},
    alias: jackbox_45at_45workHO1zr6A9GjMeta?.alias || [],
    redirect: jackbox_45at_45workHO1zr6A9GjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45at_45workHO1zr6A9GjMeta?.name ?? "jackbox-at-work___es",
    path: jackbox_45at_45workHO1zr6A9GjMeta?.path ?? "/es/jackbox-at-work",
    meta: jackbox_45at_45workHO1zr6A9GjMeta || {},
    alias: jackbox_45at_45workHO1zr6A9GjMeta?.alias || [],
    redirect: jackbox_45at_45workHO1zr6A9GjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45at_45workHO1zr6A9GjMeta?.name ?? "jackbox-at-work___es-XL",
    path: jackbox_45at_45workHO1zr6A9GjMeta?.path ?? "/es-XL/jackbox-at-work",
    meta: jackbox_45at_45workHO1zr6A9GjMeta || {},
    alias: jackbox_45at_45workHO1zr6A9GjMeta?.alias || [],
    redirect: jackbox_45at_45workHO1zr6A9GjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45at_45workHO1zr6A9GjMeta?.name ?? "jackbox-at-work___pt-BR",
    path: jackbox_45at_45workHO1zr6A9GjMeta?.path ?? "/pt-BR/jackbox-at-work",
    meta: jackbox_45at_45workHO1zr6A9GjMeta || {},
    alias: jackbox_45at_45workHO1zr6A9GjMeta?.alias || [],
    redirect: jackbox_45at_45workHO1zr6A9GjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-at-work.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45eventsyMN3nKOJZOMeta?.name ?? "jackbox-for-events___en",
    path: jackbox_45for_45eventsyMN3nKOJZOMeta?.path ?? "/jackbox-for-events",
    meta: jackbox_45for_45eventsyMN3nKOJZOMeta || {},
    alias: jackbox_45for_45eventsyMN3nKOJZOMeta?.alias || [],
    redirect: jackbox_45for_45eventsyMN3nKOJZOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45eventsyMN3nKOJZOMeta?.name ?? "jackbox-for-events___fr",
    path: jackbox_45for_45eventsyMN3nKOJZOMeta?.path ?? "/fr/jackbox-for-events",
    meta: jackbox_45for_45eventsyMN3nKOJZOMeta || {},
    alias: jackbox_45for_45eventsyMN3nKOJZOMeta?.alias || [],
    redirect: jackbox_45for_45eventsyMN3nKOJZOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45eventsyMN3nKOJZOMeta?.name ?? "jackbox-for-events___it",
    path: jackbox_45for_45eventsyMN3nKOJZOMeta?.path ?? "/it/jackbox-for-events",
    meta: jackbox_45for_45eventsyMN3nKOJZOMeta || {},
    alias: jackbox_45for_45eventsyMN3nKOJZOMeta?.alias || [],
    redirect: jackbox_45for_45eventsyMN3nKOJZOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45eventsyMN3nKOJZOMeta?.name ?? "jackbox-for-events___de",
    path: jackbox_45for_45eventsyMN3nKOJZOMeta?.path ?? "/de/jackbox-for-events",
    meta: jackbox_45for_45eventsyMN3nKOJZOMeta || {},
    alias: jackbox_45for_45eventsyMN3nKOJZOMeta?.alias || [],
    redirect: jackbox_45for_45eventsyMN3nKOJZOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45eventsyMN3nKOJZOMeta?.name ?? "jackbox-for-events___es",
    path: jackbox_45for_45eventsyMN3nKOJZOMeta?.path ?? "/es/jackbox-for-events",
    meta: jackbox_45for_45eventsyMN3nKOJZOMeta || {},
    alias: jackbox_45for_45eventsyMN3nKOJZOMeta?.alias || [],
    redirect: jackbox_45for_45eventsyMN3nKOJZOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45eventsyMN3nKOJZOMeta?.name ?? "jackbox-for-events___es-XL",
    path: jackbox_45for_45eventsyMN3nKOJZOMeta?.path ?? "/es-XL/jackbox-for-events",
    meta: jackbox_45for_45eventsyMN3nKOJZOMeta || {},
    alias: jackbox_45for_45eventsyMN3nKOJZOMeta?.alias || [],
    redirect: jackbox_45for_45eventsyMN3nKOJZOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45eventsyMN3nKOJZOMeta?.name ?? "jackbox-for-events___pt-BR",
    path: jackbox_45for_45eventsyMN3nKOJZOMeta?.path ?? "/pt-BR/jackbox-for-events",
    meta: jackbox_45for_45eventsyMN3nKOJZOMeta || {},
    alias: jackbox_45for_45eventsyMN3nKOJZOMeta?.alias || [],
    redirect: jackbox_45for_45eventsyMN3nKOJZOMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-events.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45familiesyrXQpzlGKaMeta?.name ?? "jackbox-for-families___en",
    path: jackbox_45for_45familiesyrXQpzlGKaMeta?.path ?? "/jackbox-for-families",
    meta: jackbox_45for_45familiesyrXQpzlGKaMeta || {},
    alias: jackbox_45for_45familiesyrXQpzlGKaMeta?.alias || [],
    redirect: jackbox_45for_45familiesyrXQpzlGKaMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45familiesyrXQpzlGKaMeta?.name ?? "jackbox-for-families___fr",
    path: jackbox_45for_45familiesyrXQpzlGKaMeta?.path ?? "/fr/jackbox-for-families",
    meta: jackbox_45for_45familiesyrXQpzlGKaMeta || {},
    alias: jackbox_45for_45familiesyrXQpzlGKaMeta?.alias || [],
    redirect: jackbox_45for_45familiesyrXQpzlGKaMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45familiesyrXQpzlGKaMeta?.name ?? "jackbox-for-families___it",
    path: jackbox_45for_45familiesyrXQpzlGKaMeta?.path ?? "/it/jackbox-for-families",
    meta: jackbox_45for_45familiesyrXQpzlGKaMeta || {},
    alias: jackbox_45for_45familiesyrXQpzlGKaMeta?.alias || [],
    redirect: jackbox_45for_45familiesyrXQpzlGKaMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45familiesyrXQpzlGKaMeta?.name ?? "jackbox-for-families___de",
    path: jackbox_45for_45familiesyrXQpzlGKaMeta?.path ?? "/de/jackbox-for-families",
    meta: jackbox_45for_45familiesyrXQpzlGKaMeta || {},
    alias: jackbox_45for_45familiesyrXQpzlGKaMeta?.alias || [],
    redirect: jackbox_45for_45familiesyrXQpzlGKaMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45familiesyrXQpzlGKaMeta?.name ?? "jackbox-for-families___es",
    path: jackbox_45for_45familiesyrXQpzlGKaMeta?.path ?? "/es/jackbox-for-families",
    meta: jackbox_45for_45familiesyrXQpzlGKaMeta || {},
    alias: jackbox_45for_45familiesyrXQpzlGKaMeta?.alias || [],
    redirect: jackbox_45for_45familiesyrXQpzlGKaMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45familiesyrXQpzlGKaMeta?.name ?? "jackbox-for-families___es-XL",
    path: jackbox_45for_45familiesyrXQpzlGKaMeta?.path ?? "/es-XL/jackbox-for-families",
    meta: jackbox_45for_45familiesyrXQpzlGKaMeta || {},
    alias: jackbox_45for_45familiesyrXQpzlGKaMeta?.alias || [],
    redirect: jackbox_45for_45familiesyrXQpzlGKaMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45familiesyrXQpzlGKaMeta?.name ?? "jackbox-for-families___pt-BR",
    path: jackbox_45for_45familiesyrXQpzlGKaMeta?.path ?? "/pt-BR/jackbox-for-families",
    meta: jackbox_45for_45familiesyrXQpzlGKaMeta || {},
    alias: jackbox_45for_45familiesyrXQpzlGKaMeta?.alias || [],
    redirect: jackbox_45for_45familiesyrXQpzlGKaMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-families.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.name ?? "jackbox-for-game-night___en",
    path: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.path ?? "/jackbox-for-game-night",
    meta: jackbox_45for_45game_45nightZFgT7n3KjzMeta || {},
    alias: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.alias || [],
    redirect: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.name ?? "jackbox-for-game-night___fr",
    path: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.path ?? "/fr/jackbox-for-game-night",
    meta: jackbox_45for_45game_45nightZFgT7n3KjzMeta || {},
    alias: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.alias || [],
    redirect: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.name ?? "jackbox-for-game-night___it",
    path: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.path ?? "/it/jackbox-for-game-night",
    meta: jackbox_45for_45game_45nightZFgT7n3KjzMeta || {},
    alias: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.alias || [],
    redirect: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.name ?? "jackbox-for-game-night___de",
    path: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.path ?? "/de/jackbox-for-game-night",
    meta: jackbox_45for_45game_45nightZFgT7n3KjzMeta || {},
    alias: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.alias || [],
    redirect: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.name ?? "jackbox-for-game-night___es",
    path: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.path ?? "/es/jackbox-for-game-night",
    meta: jackbox_45for_45game_45nightZFgT7n3KjzMeta || {},
    alias: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.alias || [],
    redirect: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.name ?? "jackbox-for-game-night___es-XL",
    path: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.path ?? "/es-XL/jackbox-for-game-night",
    meta: jackbox_45for_45game_45nightZFgT7n3KjzMeta || {},
    alias: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.alias || [],
    redirect: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.name ?? "jackbox-for-game-night___pt-BR",
    path: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.path ?? "/pt-BR/jackbox-for-game-night",
    meta: jackbox_45for_45game_45nightZFgT7n3KjzMeta || {},
    alias: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.alias || [],
    redirect: jackbox_45for_45game_45nightZFgT7n3KjzMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-game-night.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45streamersq2zK2UarKZMeta?.name ?? "jackbox-for-streamers___en",
    path: jackbox_45for_45streamersq2zK2UarKZMeta?.path ?? "/jackbox-for-streamers",
    meta: jackbox_45for_45streamersq2zK2UarKZMeta || {},
    alias: jackbox_45for_45streamersq2zK2UarKZMeta?.alias || [],
    redirect: jackbox_45for_45streamersq2zK2UarKZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45streamersq2zK2UarKZMeta?.name ?? "jackbox-for-streamers___fr",
    path: jackbox_45for_45streamersq2zK2UarKZMeta?.path ?? "/fr/jackbox-for-streamers",
    meta: jackbox_45for_45streamersq2zK2UarKZMeta || {},
    alias: jackbox_45for_45streamersq2zK2UarKZMeta?.alias || [],
    redirect: jackbox_45for_45streamersq2zK2UarKZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45streamersq2zK2UarKZMeta?.name ?? "jackbox-for-streamers___it",
    path: jackbox_45for_45streamersq2zK2UarKZMeta?.path ?? "/it/jackbox-for-streamers",
    meta: jackbox_45for_45streamersq2zK2UarKZMeta || {},
    alias: jackbox_45for_45streamersq2zK2UarKZMeta?.alias || [],
    redirect: jackbox_45for_45streamersq2zK2UarKZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45streamersq2zK2UarKZMeta?.name ?? "jackbox-for-streamers___de",
    path: jackbox_45for_45streamersq2zK2UarKZMeta?.path ?? "/de/jackbox-for-streamers",
    meta: jackbox_45for_45streamersq2zK2UarKZMeta || {},
    alias: jackbox_45for_45streamersq2zK2UarKZMeta?.alias || [],
    redirect: jackbox_45for_45streamersq2zK2UarKZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45streamersq2zK2UarKZMeta?.name ?? "jackbox-for-streamers___es",
    path: jackbox_45for_45streamersq2zK2UarKZMeta?.path ?? "/es/jackbox-for-streamers",
    meta: jackbox_45for_45streamersq2zK2UarKZMeta || {},
    alias: jackbox_45for_45streamersq2zK2UarKZMeta?.alias || [],
    redirect: jackbox_45for_45streamersq2zK2UarKZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45streamersq2zK2UarKZMeta?.name ?? "jackbox-for-streamers___es-XL",
    path: jackbox_45for_45streamersq2zK2UarKZMeta?.path ?? "/es-XL/jackbox-for-streamers",
    meta: jackbox_45for_45streamersq2zK2UarKZMeta || {},
    alias: jackbox_45for_45streamersq2zK2UarKZMeta?.alias || [],
    redirect: jackbox_45for_45streamersq2zK2UarKZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45streamersq2zK2UarKZMeta?.name ?? "jackbox-for-streamers___pt-BR",
    path: jackbox_45for_45streamersq2zK2UarKZMeta?.path ?? "/pt-BR/jackbox-for-streamers",
    meta: jackbox_45for_45streamersq2zK2UarKZMeta || {},
    alias: jackbox_45for_45streamersq2zK2UarKZMeta?.alias || [],
    redirect: jackbox_45for_45streamersq2zK2UarKZMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-streamers.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45studentsrPvv31zCxDMeta?.name ?? "jackbox-for-students___en",
    path: jackbox_45for_45studentsrPvv31zCxDMeta?.path ?? "/jackbox-for-students",
    meta: jackbox_45for_45studentsrPvv31zCxDMeta || {},
    alias: jackbox_45for_45studentsrPvv31zCxDMeta?.alias || [],
    redirect: jackbox_45for_45studentsrPvv31zCxDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45studentsrPvv31zCxDMeta?.name ?? "jackbox-for-students___fr",
    path: jackbox_45for_45studentsrPvv31zCxDMeta?.path ?? "/fr/jackbox-for-students",
    meta: jackbox_45for_45studentsrPvv31zCxDMeta || {},
    alias: jackbox_45for_45studentsrPvv31zCxDMeta?.alias || [],
    redirect: jackbox_45for_45studentsrPvv31zCxDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45studentsrPvv31zCxDMeta?.name ?? "jackbox-for-students___it",
    path: jackbox_45for_45studentsrPvv31zCxDMeta?.path ?? "/it/jackbox-for-students",
    meta: jackbox_45for_45studentsrPvv31zCxDMeta || {},
    alias: jackbox_45for_45studentsrPvv31zCxDMeta?.alias || [],
    redirect: jackbox_45for_45studentsrPvv31zCxDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45studentsrPvv31zCxDMeta?.name ?? "jackbox-for-students___de",
    path: jackbox_45for_45studentsrPvv31zCxDMeta?.path ?? "/de/jackbox-for-students",
    meta: jackbox_45for_45studentsrPvv31zCxDMeta || {},
    alias: jackbox_45for_45studentsrPvv31zCxDMeta?.alias || [],
    redirect: jackbox_45for_45studentsrPvv31zCxDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45studentsrPvv31zCxDMeta?.name ?? "jackbox-for-students___es",
    path: jackbox_45for_45studentsrPvv31zCxDMeta?.path ?? "/es/jackbox-for-students",
    meta: jackbox_45for_45studentsrPvv31zCxDMeta || {},
    alias: jackbox_45for_45studentsrPvv31zCxDMeta?.alias || [],
    redirect: jackbox_45for_45studentsrPvv31zCxDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45studentsrPvv31zCxDMeta?.name ?? "jackbox-for-students___es-XL",
    path: jackbox_45for_45studentsrPvv31zCxDMeta?.path ?? "/es-XL/jackbox-for-students",
    meta: jackbox_45for_45studentsrPvv31zCxDMeta || {},
    alias: jackbox_45for_45studentsrPvv31zCxDMeta?.alias || [],
    redirect: jackbox_45for_45studentsrPvv31zCxDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: jackbox_45for_45studentsrPvv31zCxDMeta?.name ?? "jackbox-for-students___pt-BR",
    path: jackbox_45for_45studentsrPvv31zCxDMeta?.path ?? "/pt-BR/jackbox-for-students",
    meta: jackbox_45for_45studentsrPvv31zCxDMeta || {},
    alias: jackbox_45for_45studentsrPvv31zCxDMeta?.alias || [],
    redirect: jackbox_45for_45studentsrPvv31zCxDMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jackbox-for-students.vue").then(m => m.default || m)
  },
  {
    name: jobsjn4P50HT8dMeta?.name ?? "jobs___en",
    path: jobsjn4P50HT8dMeta?.path ?? "/jobs",
    meta: jobsjn4P50HT8dMeta || {},
    alias: jobsjn4P50HT8dMeta?.alias || [],
    redirect: jobsjn4P50HT8dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: jobsjn4P50HT8dMeta?.name ?? "jobs___fr",
    path: jobsjn4P50HT8dMeta?.path ?? "/fr/jobs",
    meta: jobsjn4P50HT8dMeta || {},
    alias: jobsjn4P50HT8dMeta?.alias || [],
    redirect: jobsjn4P50HT8dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: jobsjn4P50HT8dMeta?.name ?? "jobs___it",
    path: jobsjn4P50HT8dMeta?.path ?? "/it/jobs",
    meta: jobsjn4P50HT8dMeta || {},
    alias: jobsjn4P50HT8dMeta?.alias || [],
    redirect: jobsjn4P50HT8dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: jobsjn4P50HT8dMeta?.name ?? "jobs___de",
    path: jobsjn4P50HT8dMeta?.path ?? "/de/jobs",
    meta: jobsjn4P50HT8dMeta || {},
    alias: jobsjn4P50HT8dMeta?.alias || [],
    redirect: jobsjn4P50HT8dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: jobsjn4P50HT8dMeta?.name ?? "jobs___es",
    path: jobsjn4P50HT8dMeta?.path ?? "/es/jobs",
    meta: jobsjn4P50HT8dMeta || {},
    alias: jobsjn4P50HT8dMeta?.alias || [],
    redirect: jobsjn4P50HT8dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: jobsjn4P50HT8dMeta?.name ?? "jobs___es-XL",
    path: jobsjn4P50HT8dMeta?.path ?? "/es-XL/jobs",
    meta: jobsjn4P50HT8dMeta || {},
    alias: jobsjn4P50HT8dMeta?.alias || [],
    redirect: jobsjn4P50HT8dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: jobsjn4P50HT8dMeta?.name ?? "jobs___pt-BR",
    path: jobsjn4P50HT8dMeta?.path ?? "/pt-BR/jobs",
    meta: jobsjn4P50HT8dMeta || {},
    alias: jobsjn4P50HT8dMeta?.alias || [],
    redirect: jobsjn4P50HT8dMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login___en",
    path: loginpJbw2IDcKUMeta?.path ?? "/login",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login___fr",
    path: loginpJbw2IDcKUMeta?.path ?? "/fr/login",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login___it",
    path: loginpJbw2IDcKUMeta?.path ?? "/it/login",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login___de",
    path: loginpJbw2IDcKUMeta?.path ?? "/de/login",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login___es",
    path: loginpJbw2IDcKUMeta?.path ?? "/es/login",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login___es-XL",
    path: loginpJbw2IDcKUMeta?.path ?? "/es-XL/login",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login___pt-BR",
    path: loginpJbw2IDcKUMeta?.path ?? "/pt-BR/login",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mysterious_45teaserKfS18hE3RAMeta?.name ?? "mysterious-teaser___en",
    path: mysterious_45teaserKfS18hE3RAMeta?.path ?? "/mysterious-teaser",
    meta: mysterious_45teaserKfS18hE3RAMeta || {},
    alias: mysterious_45teaserKfS18hE3RAMeta?.alias || [],
    redirect: mysterious_45teaserKfS18hE3RAMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: mysterious_45teaserKfS18hE3RAMeta?.name ?? "mysterious-teaser___fr",
    path: mysterious_45teaserKfS18hE3RAMeta?.path ?? "/fr/mysterious-teaser",
    meta: mysterious_45teaserKfS18hE3RAMeta || {},
    alias: mysterious_45teaserKfS18hE3RAMeta?.alias || [],
    redirect: mysterious_45teaserKfS18hE3RAMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: mysterious_45teaserKfS18hE3RAMeta?.name ?? "mysterious-teaser___it",
    path: mysterious_45teaserKfS18hE3RAMeta?.path ?? "/it/mysterious-teaser",
    meta: mysterious_45teaserKfS18hE3RAMeta || {},
    alias: mysterious_45teaserKfS18hE3RAMeta?.alias || [],
    redirect: mysterious_45teaserKfS18hE3RAMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: mysterious_45teaserKfS18hE3RAMeta?.name ?? "mysterious-teaser___de",
    path: mysterious_45teaserKfS18hE3RAMeta?.path ?? "/de/mysterious-teaser",
    meta: mysterious_45teaserKfS18hE3RAMeta || {},
    alias: mysterious_45teaserKfS18hE3RAMeta?.alias || [],
    redirect: mysterious_45teaserKfS18hE3RAMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: mysterious_45teaserKfS18hE3RAMeta?.name ?? "mysterious-teaser___es",
    path: mysterious_45teaserKfS18hE3RAMeta?.path ?? "/es/mysterious-teaser",
    meta: mysterious_45teaserKfS18hE3RAMeta || {},
    alias: mysterious_45teaserKfS18hE3RAMeta?.alias || [],
    redirect: mysterious_45teaserKfS18hE3RAMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: mysterious_45teaserKfS18hE3RAMeta?.name ?? "mysterious-teaser___es-XL",
    path: mysterious_45teaserKfS18hE3RAMeta?.path ?? "/es-XL/mysterious-teaser",
    meta: mysterious_45teaserKfS18hE3RAMeta || {},
    alias: mysterious_45teaserKfS18hE3RAMeta?.alias || [],
    redirect: mysterious_45teaserKfS18hE3RAMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: mysterious_45teaserKfS18hE3RAMeta?.name ?? "mysterious-teaser___pt-BR",
    path: mysterious_45teaserKfS18hE3RAMeta?.path ?? "/pt-BR/mysterious-teaser",
    meta: mysterious_45teaserKfS18hE3RAMeta || {},
    alias: mysterious_45teaserKfS18hE3RAMeta?.alias || [],
    redirect: mysterious_45teaserKfS18hE3RAMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/mysterious-teaser.vue").then(m => m.default || m)
  },
  {
    name: indexDapNxU7YqPMeta?.name ?? "play___en",
    path: indexDapNxU7YqPMeta?.path ?? "/play",
    meta: indexDapNxU7YqPMeta || {},
    alias: indexDapNxU7YqPMeta?.alias || [],
    redirect: indexDapNxU7YqPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: indexDapNxU7YqPMeta?.name ?? "play___fr",
    path: indexDapNxU7YqPMeta?.path ?? "/fr/play",
    meta: indexDapNxU7YqPMeta || {},
    alias: indexDapNxU7YqPMeta?.alias || [],
    redirect: indexDapNxU7YqPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: indexDapNxU7YqPMeta?.name ?? "play___it",
    path: indexDapNxU7YqPMeta?.path ?? "/it/play",
    meta: indexDapNxU7YqPMeta || {},
    alias: indexDapNxU7YqPMeta?.alias || [],
    redirect: indexDapNxU7YqPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: indexDapNxU7YqPMeta?.name ?? "play___de",
    path: indexDapNxU7YqPMeta?.path ?? "/de/play",
    meta: indexDapNxU7YqPMeta || {},
    alias: indexDapNxU7YqPMeta?.alias || [],
    redirect: indexDapNxU7YqPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: indexDapNxU7YqPMeta?.name ?? "play___es",
    path: indexDapNxU7YqPMeta?.path ?? "/es/play",
    meta: indexDapNxU7YqPMeta || {},
    alias: indexDapNxU7YqPMeta?.alias || [],
    redirect: indexDapNxU7YqPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: indexDapNxU7YqPMeta?.name ?? "play___es-XL",
    path: indexDapNxU7YqPMeta?.path ?? "/es-XL/play",
    meta: indexDapNxU7YqPMeta || {},
    alias: indexDapNxU7YqPMeta?.alias || [],
    redirect: indexDapNxU7YqPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: indexDapNxU7YqPMeta?.name ?? "play___pt-BR",
    path: indexDapNxU7YqPMeta?.path ?? "/pt-BR/play",
    meta: indexDapNxU7YqPMeta || {},
    alias: indexDapNxU7YqPMeta?.alias || [],
    redirect: indexDapNxU7YqPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: preference_45centerWnl7uDuVQjMeta?.name ?? "preference-center___en",
    path: preference_45centerWnl7uDuVQjMeta?.path ?? "/preference-center",
    meta: preference_45centerWnl7uDuVQjMeta || {},
    alias: preference_45centerWnl7uDuVQjMeta?.alias || [],
    redirect: preference_45centerWnl7uDuVQjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: preference_45centerWnl7uDuVQjMeta?.name ?? "preference-center___fr",
    path: preference_45centerWnl7uDuVQjMeta?.path ?? "/fr/preference-center",
    meta: preference_45centerWnl7uDuVQjMeta || {},
    alias: preference_45centerWnl7uDuVQjMeta?.alias || [],
    redirect: preference_45centerWnl7uDuVQjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: preference_45centerWnl7uDuVQjMeta?.name ?? "preference-center___it",
    path: preference_45centerWnl7uDuVQjMeta?.path ?? "/it/preference-center",
    meta: preference_45centerWnl7uDuVQjMeta || {},
    alias: preference_45centerWnl7uDuVQjMeta?.alias || [],
    redirect: preference_45centerWnl7uDuVQjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: preference_45centerWnl7uDuVQjMeta?.name ?? "preference-center___de",
    path: preference_45centerWnl7uDuVQjMeta?.path ?? "/de/preference-center",
    meta: preference_45centerWnl7uDuVQjMeta || {},
    alias: preference_45centerWnl7uDuVQjMeta?.alias || [],
    redirect: preference_45centerWnl7uDuVQjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: preference_45centerWnl7uDuVQjMeta?.name ?? "preference-center___es",
    path: preference_45centerWnl7uDuVQjMeta?.path ?? "/es/preference-center",
    meta: preference_45centerWnl7uDuVQjMeta || {},
    alias: preference_45centerWnl7uDuVQjMeta?.alias || [],
    redirect: preference_45centerWnl7uDuVQjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: preference_45centerWnl7uDuVQjMeta?.name ?? "preference-center___es-XL",
    path: preference_45centerWnl7uDuVQjMeta?.path ?? "/es-XL/preference-center",
    meta: preference_45centerWnl7uDuVQjMeta || {},
    alias: preference_45centerWnl7uDuVQjMeta?.alias || [],
    redirect: preference_45centerWnl7uDuVQjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: preference_45centerWnl7uDuVQjMeta?.name ?? "preference-center___pt-BR",
    path: preference_45centerWnl7uDuVQjMeta?.path ?? "/pt-BR/preference-center",
    meta: preference_45centerWnl7uDuVQjMeta || {},
    alias: preference_45centerWnl7uDuVQjMeta?.alias || [],
    redirect: preference_45centerWnl7uDuVQjMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/preference-center.vue").then(m => m.default || m)
  },
  {
    name: presskit7jVutcqXBnMeta?.name ?? "presskit___en",
    path: presskit7jVutcqXBnMeta?.path ?? "/presskit",
    meta: presskit7jVutcqXBnMeta || {},
    alias: presskit7jVutcqXBnMeta?.alias || [],
    redirect: presskit7jVutcqXBnMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: presskit7jVutcqXBnMeta?.name ?? "presskit___fr",
    path: presskit7jVutcqXBnMeta?.path ?? "/fr/presskit",
    meta: presskit7jVutcqXBnMeta || {},
    alias: presskit7jVutcqXBnMeta?.alias || [],
    redirect: presskit7jVutcqXBnMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: presskit7jVutcqXBnMeta?.name ?? "presskit___it",
    path: presskit7jVutcqXBnMeta?.path ?? "/it/presskit",
    meta: presskit7jVutcqXBnMeta || {},
    alias: presskit7jVutcqXBnMeta?.alias || [],
    redirect: presskit7jVutcqXBnMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: presskit7jVutcqXBnMeta?.name ?? "presskit___de",
    path: presskit7jVutcqXBnMeta?.path ?? "/de/presskit",
    meta: presskit7jVutcqXBnMeta || {},
    alias: presskit7jVutcqXBnMeta?.alias || [],
    redirect: presskit7jVutcqXBnMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: presskit7jVutcqXBnMeta?.name ?? "presskit___es",
    path: presskit7jVutcqXBnMeta?.path ?? "/es/presskit",
    meta: presskit7jVutcqXBnMeta || {},
    alias: presskit7jVutcqXBnMeta?.alias || [],
    redirect: presskit7jVutcqXBnMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: presskit7jVutcqXBnMeta?.name ?? "presskit___es-XL",
    path: presskit7jVutcqXBnMeta?.path ?? "/es-XL/presskit",
    meta: presskit7jVutcqXBnMeta || {},
    alias: presskit7jVutcqXBnMeta?.alias || [],
    redirect: presskit7jVutcqXBnMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: presskit7jVutcqXBnMeta?.name ?? "presskit___pt-BR",
    path: presskit7jVutcqXBnMeta?.path ?? "/pt-BR/presskit",
    meta: presskit7jVutcqXBnMeta || {},
    alias: presskit7jVutcqXBnMeta?.alias || [],
    redirect: presskit7jVutcqXBnMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/presskit.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyHVbNabwlNsMeta?.name ?? "privacy-policy___en",
    path: privacy_45policyHVbNabwlNsMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyHVbNabwlNsMeta || {},
    alias: privacy_45policyHVbNabwlNsMeta?.alias || [],
    redirect: privacy_45policyHVbNabwlNsMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyHVbNabwlNsMeta?.name ?? "privacy-policy___fr",
    path: privacy_45policyHVbNabwlNsMeta?.path ?? "/fr/privacy-policy",
    meta: privacy_45policyHVbNabwlNsMeta || {},
    alias: privacy_45policyHVbNabwlNsMeta?.alias || [],
    redirect: privacy_45policyHVbNabwlNsMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyHVbNabwlNsMeta?.name ?? "privacy-policy___it",
    path: privacy_45policyHVbNabwlNsMeta?.path ?? "/it/privacy-policy",
    meta: privacy_45policyHVbNabwlNsMeta || {},
    alias: privacy_45policyHVbNabwlNsMeta?.alias || [],
    redirect: privacy_45policyHVbNabwlNsMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyHVbNabwlNsMeta?.name ?? "privacy-policy___de",
    path: privacy_45policyHVbNabwlNsMeta?.path ?? "/de/privacy-policy",
    meta: privacy_45policyHVbNabwlNsMeta || {},
    alias: privacy_45policyHVbNabwlNsMeta?.alias || [],
    redirect: privacy_45policyHVbNabwlNsMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyHVbNabwlNsMeta?.name ?? "privacy-policy___es",
    path: privacy_45policyHVbNabwlNsMeta?.path ?? "/es/privacy-policy",
    meta: privacy_45policyHVbNabwlNsMeta || {},
    alias: privacy_45policyHVbNabwlNsMeta?.alias || [],
    redirect: privacy_45policyHVbNabwlNsMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyHVbNabwlNsMeta?.name ?? "privacy-policy___es-XL",
    path: privacy_45policyHVbNabwlNsMeta?.path ?? "/es-XL/privacy-policy",
    meta: privacy_45policyHVbNabwlNsMeta || {},
    alias: privacy_45policyHVbNabwlNsMeta?.alias || [],
    redirect: privacy_45policyHVbNabwlNsMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyHVbNabwlNsMeta?.name ?? "privacy-policy___pt-BR",
    path: privacy_45policyHVbNabwlNsMeta?.path ?? "/pt-BR/privacy-policy",
    meta: privacy_45policyHVbNabwlNsMeta || {},
    alias: privacy_45policyHVbNabwlNsMeta?.alias || [],
    redirect: privacy_45policyHVbNabwlNsMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91tab_45slug_93tM7aQl65i4Meta?.name ?? "shop-tabslug___en",
    path: _91tab_45slug_93tM7aQl65i4Meta?.path ?? "/shop/:tabslug()",
    meta: _91tab_45slug_93tM7aQl65i4Meta || {},
    alias: _91tab_45slug_93tM7aQl65i4Meta?.alias || [],
    redirect: _91tab_45slug_93tM7aQl65i4Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/[tab-slug].vue").then(m => m.default || m)
  },
  {
    name: _91tab_45slug_93tM7aQl65i4Meta?.name ?? "shop-tabslug___fr",
    path: _91tab_45slug_93tM7aQl65i4Meta?.path ?? "/fr/shop/:tabslug()",
    meta: _91tab_45slug_93tM7aQl65i4Meta || {},
    alias: _91tab_45slug_93tM7aQl65i4Meta?.alias || [],
    redirect: _91tab_45slug_93tM7aQl65i4Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/[tab-slug].vue").then(m => m.default || m)
  },
  {
    name: _91tab_45slug_93tM7aQl65i4Meta?.name ?? "shop-tabslug___it",
    path: _91tab_45slug_93tM7aQl65i4Meta?.path ?? "/it/shop/:tabslug()",
    meta: _91tab_45slug_93tM7aQl65i4Meta || {},
    alias: _91tab_45slug_93tM7aQl65i4Meta?.alias || [],
    redirect: _91tab_45slug_93tM7aQl65i4Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/[tab-slug].vue").then(m => m.default || m)
  },
  {
    name: _91tab_45slug_93tM7aQl65i4Meta?.name ?? "shop-tabslug___de",
    path: _91tab_45slug_93tM7aQl65i4Meta?.path ?? "/de/shop/:tabslug()",
    meta: _91tab_45slug_93tM7aQl65i4Meta || {},
    alias: _91tab_45slug_93tM7aQl65i4Meta?.alias || [],
    redirect: _91tab_45slug_93tM7aQl65i4Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/[tab-slug].vue").then(m => m.default || m)
  },
  {
    name: _91tab_45slug_93tM7aQl65i4Meta?.name ?? "shop-tabslug___es",
    path: _91tab_45slug_93tM7aQl65i4Meta?.path ?? "/es/shop/:tabslug()",
    meta: _91tab_45slug_93tM7aQl65i4Meta || {},
    alias: _91tab_45slug_93tM7aQl65i4Meta?.alias || [],
    redirect: _91tab_45slug_93tM7aQl65i4Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/[tab-slug].vue").then(m => m.default || m)
  },
  {
    name: _91tab_45slug_93tM7aQl65i4Meta?.name ?? "shop-tabslug___es-XL",
    path: _91tab_45slug_93tM7aQl65i4Meta?.path ?? "/es-XL/shop/:tabslug()",
    meta: _91tab_45slug_93tM7aQl65i4Meta || {},
    alias: _91tab_45slug_93tM7aQl65i4Meta?.alias || [],
    redirect: _91tab_45slug_93tM7aQl65i4Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/[tab-slug].vue").then(m => m.default || m)
  },
  {
    name: _91tab_45slug_93tM7aQl65i4Meta?.name ?? "shop-tabslug___pt-BR",
    path: _91tab_45slug_93tM7aQl65i4Meta?.path ?? "/pt-BR/shop/:tabslug()",
    meta: _91tab_45slug_93tM7aQl65i4Meta || {},
    alias: _91tab_45slug_93tM7aQl65i4Meta?.alias || [],
    redirect: _91tab_45slug_93tM7aQl65i4Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/[tab-slug].vue").then(m => m.default || m)
  },
  {
    name: indexmg4DokeYu7Meta?.name ?? "shop___en",
    path: indexmg4DokeYu7Meta?.path ?? "/shop",
    meta: indexmg4DokeYu7Meta || {},
    alias: indexmg4DokeYu7Meta?.alias || [],
    redirect: indexmg4DokeYu7Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexmg4DokeYu7Meta?.name ?? "shop___fr",
    path: indexmg4DokeYu7Meta?.path ?? "/fr/shop",
    meta: indexmg4DokeYu7Meta || {},
    alias: indexmg4DokeYu7Meta?.alias || [],
    redirect: indexmg4DokeYu7Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexmg4DokeYu7Meta?.name ?? "shop___it",
    path: indexmg4DokeYu7Meta?.path ?? "/it/shop",
    meta: indexmg4DokeYu7Meta || {},
    alias: indexmg4DokeYu7Meta?.alias || [],
    redirect: indexmg4DokeYu7Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexmg4DokeYu7Meta?.name ?? "shop___de",
    path: indexmg4DokeYu7Meta?.path ?? "/de/shop",
    meta: indexmg4DokeYu7Meta || {},
    alias: indexmg4DokeYu7Meta?.alias || [],
    redirect: indexmg4DokeYu7Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexmg4DokeYu7Meta?.name ?? "shop___es",
    path: indexmg4DokeYu7Meta?.path ?? "/es/shop",
    meta: indexmg4DokeYu7Meta || {},
    alias: indexmg4DokeYu7Meta?.alias || [],
    redirect: indexmg4DokeYu7Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexmg4DokeYu7Meta?.name ?? "shop___es-XL",
    path: indexmg4DokeYu7Meta?.path ?? "/es-XL/shop",
    meta: indexmg4DokeYu7Meta || {},
    alias: indexmg4DokeYu7Meta?.alias || [],
    redirect: indexmg4DokeYu7Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexmg4DokeYu7Meta?.name ?? "shop___pt-BR",
    path: indexmg4DokeYu7Meta?.path ?? "/pt-BR/shop",
    meta: indexmg4DokeYu7Meta || {},
    alias: indexmg4DokeYu7Meta?.alias || [],
    redirect: indexmg4DokeYu7Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: supportkIOMPEv5DKMeta?.name ?? "support___en",
    path: supportkIOMPEv5DKMeta?.path ?? "/support",
    meta: supportkIOMPEv5DKMeta || {},
    alias: supportkIOMPEv5DKMeta?.alias || [],
    redirect: supportkIOMPEv5DKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportkIOMPEv5DKMeta?.name ?? "support___fr",
    path: supportkIOMPEv5DKMeta?.path ?? "/fr/support",
    meta: supportkIOMPEv5DKMeta || {},
    alias: supportkIOMPEv5DKMeta?.alias || [],
    redirect: supportkIOMPEv5DKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportkIOMPEv5DKMeta?.name ?? "support___it",
    path: supportkIOMPEv5DKMeta?.path ?? "/it/support",
    meta: supportkIOMPEv5DKMeta || {},
    alias: supportkIOMPEv5DKMeta?.alias || [],
    redirect: supportkIOMPEv5DKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportkIOMPEv5DKMeta?.name ?? "support___de",
    path: supportkIOMPEv5DKMeta?.path ?? "/de/support",
    meta: supportkIOMPEv5DKMeta || {},
    alias: supportkIOMPEv5DKMeta?.alias || [],
    redirect: supportkIOMPEv5DKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportkIOMPEv5DKMeta?.name ?? "support___es",
    path: supportkIOMPEv5DKMeta?.path ?? "/es/support",
    meta: supportkIOMPEv5DKMeta || {},
    alias: supportkIOMPEv5DKMeta?.alias || [],
    redirect: supportkIOMPEv5DKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportkIOMPEv5DKMeta?.name ?? "support___es-XL",
    path: supportkIOMPEv5DKMeta?.path ?? "/es-XL/support",
    meta: supportkIOMPEv5DKMeta || {},
    alias: supportkIOMPEv5DKMeta?.alias || [],
    redirect: supportkIOMPEv5DKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportkIOMPEv5DKMeta?.name ?? "support___pt-BR",
    path: supportkIOMPEv5DKMeta?.path ?? "/pt-BR/support",
    meta: supportkIOMPEv5DKMeta || {},
    alias: supportkIOMPEv5DKMeta?.alias || [],
    redirect: supportkIOMPEv5DKMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceHiG6WYOnLgMeta?.name ?? "terms-of-service___en",
    path: terms_45of_45serviceHiG6WYOnLgMeta?.path ?? "/terms-of-service",
    meta: terms_45of_45serviceHiG6WYOnLgMeta || {},
    alias: terms_45of_45serviceHiG6WYOnLgMeta?.alias || [],
    redirect: terms_45of_45serviceHiG6WYOnLgMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceHiG6WYOnLgMeta?.name ?? "terms-of-service___fr",
    path: terms_45of_45serviceHiG6WYOnLgMeta?.path ?? "/fr/terms-of-service",
    meta: terms_45of_45serviceHiG6WYOnLgMeta || {},
    alias: terms_45of_45serviceHiG6WYOnLgMeta?.alias || [],
    redirect: terms_45of_45serviceHiG6WYOnLgMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceHiG6WYOnLgMeta?.name ?? "terms-of-service___it",
    path: terms_45of_45serviceHiG6WYOnLgMeta?.path ?? "/it/terms-of-service",
    meta: terms_45of_45serviceHiG6WYOnLgMeta || {},
    alias: terms_45of_45serviceHiG6WYOnLgMeta?.alias || [],
    redirect: terms_45of_45serviceHiG6WYOnLgMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceHiG6WYOnLgMeta?.name ?? "terms-of-service___de",
    path: terms_45of_45serviceHiG6WYOnLgMeta?.path ?? "/de/terms-of-service",
    meta: terms_45of_45serviceHiG6WYOnLgMeta || {},
    alias: terms_45of_45serviceHiG6WYOnLgMeta?.alias || [],
    redirect: terms_45of_45serviceHiG6WYOnLgMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceHiG6WYOnLgMeta?.name ?? "terms-of-service___es",
    path: terms_45of_45serviceHiG6WYOnLgMeta?.path ?? "/es/terms-of-service",
    meta: terms_45of_45serviceHiG6WYOnLgMeta || {},
    alias: terms_45of_45serviceHiG6WYOnLgMeta?.alias || [],
    redirect: terms_45of_45serviceHiG6WYOnLgMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceHiG6WYOnLgMeta?.name ?? "terms-of-service___es-XL",
    path: terms_45of_45serviceHiG6WYOnLgMeta?.path ?? "/es-XL/terms-of-service",
    meta: terms_45of_45serviceHiG6WYOnLgMeta || {},
    alias: terms_45of_45serviceHiG6WYOnLgMeta?.alias || [],
    redirect: terms_45of_45serviceHiG6WYOnLgMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceHiG6WYOnLgMeta?.name ?? "terms-of-service___pt-BR",
    path: terms_45of_45serviceHiG6WYOnLgMeta?.path ?? "/pt-BR/terms-of-service",
    meta: terms_45of_45serviceHiG6WYOnLgMeta || {},
    alias: terms_45of_45serviceHiG6WYOnLgMeta?.alias || [],
    redirect: terms_45of_45serviceHiG6WYOnLgMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker___en",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/the-jackbox-megapicker",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker___fr",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/fr/the-jackbox-megapicker",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker___it",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/it/the-jackbox-megapicker",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker___de",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/de/the-jackbox-megapicker",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker___es",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/es/the-jackbox-megapicker",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker___es-XL",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/es-XL/the-jackbox-megapicker",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker___pt-BR",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/pt-BR/the-jackbox-megapicker",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: tokenAd3wHcwDQmMeta?.name ?? "token___en",
    path: tokenAd3wHcwDQmMeta?.path ?? "/token",
    meta: tokenAd3wHcwDQmMeta || {},
    alias: tokenAd3wHcwDQmMeta?.alias || [],
    redirect: tokenAd3wHcwDQmMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: tokenAd3wHcwDQmMeta?.name ?? "token___fr",
    path: tokenAd3wHcwDQmMeta?.path ?? "/fr/token",
    meta: tokenAd3wHcwDQmMeta || {},
    alias: tokenAd3wHcwDQmMeta?.alias || [],
    redirect: tokenAd3wHcwDQmMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: tokenAd3wHcwDQmMeta?.name ?? "token___it",
    path: tokenAd3wHcwDQmMeta?.path ?? "/it/token",
    meta: tokenAd3wHcwDQmMeta || {},
    alias: tokenAd3wHcwDQmMeta?.alias || [],
    redirect: tokenAd3wHcwDQmMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: tokenAd3wHcwDQmMeta?.name ?? "token___de",
    path: tokenAd3wHcwDQmMeta?.path ?? "/de/token",
    meta: tokenAd3wHcwDQmMeta || {},
    alias: tokenAd3wHcwDQmMeta?.alias || [],
    redirect: tokenAd3wHcwDQmMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: tokenAd3wHcwDQmMeta?.name ?? "token___es",
    path: tokenAd3wHcwDQmMeta?.path ?? "/es/token",
    meta: tokenAd3wHcwDQmMeta || {},
    alias: tokenAd3wHcwDQmMeta?.alias || [],
    redirect: tokenAd3wHcwDQmMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: tokenAd3wHcwDQmMeta?.name ?? "token___es-XL",
    path: tokenAd3wHcwDQmMeta?.path ?? "/es-XL/token",
    meta: tokenAd3wHcwDQmMeta || {},
    alias: tokenAd3wHcwDQmMeta?.alias || [],
    redirect: tokenAd3wHcwDQmMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: tokenAd3wHcwDQmMeta?.name ?? "token___pt-BR",
    path: tokenAd3wHcwDQmMeta?.path ?? "/pt-BR/token",
    meta: tokenAd3wHcwDQmMeta || {},
    alias: tokenAd3wHcwDQmMeta?.alias || [],
    redirect: tokenAd3wHcwDQmMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/token.vue").then(m => m.default || m)
  },
  {
    name: forgotpasswordJ2iIcuCsApMeta?.name ?? "user-forgotpassword___en",
    path: forgotpasswordJ2iIcuCsApMeta?.path ?? "/user/forgotpassword",
    meta: forgotpasswordJ2iIcuCsApMeta || {},
    alias: forgotpasswordJ2iIcuCsApMeta?.alias || [],
    redirect: forgotpasswordJ2iIcuCsApMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: forgotpasswordJ2iIcuCsApMeta?.name ?? "user-forgotpassword___fr",
    path: forgotpasswordJ2iIcuCsApMeta?.path ?? "/fr/user/forgotpassword",
    meta: forgotpasswordJ2iIcuCsApMeta || {},
    alias: forgotpasswordJ2iIcuCsApMeta?.alias || [],
    redirect: forgotpasswordJ2iIcuCsApMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: forgotpasswordJ2iIcuCsApMeta?.name ?? "user-forgotpassword___it",
    path: forgotpasswordJ2iIcuCsApMeta?.path ?? "/it/user/forgotpassword",
    meta: forgotpasswordJ2iIcuCsApMeta || {},
    alias: forgotpasswordJ2iIcuCsApMeta?.alias || [],
    redirect: forgotpasswordJ2iIcuCsApMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: forgotpasswordJ2iIcuCsApMeta?.name ?? "user-forgotpassword___de",
    path: forgotpasswordJ2iIcuCsApMeta?.path ?? "/de/user/forgotpassword",
    meta: forgotpasswordJ2iIcuCsApMeta || {},
    alias: forgotpasswordJ2iIcuCsApMeta?.alias || [],
    redirect: forgotpasswordJ2iIcuCsApMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: forgotpasswordJ2iIcuCsApMeta?.name ?? "user-forgotpassword___es",
    path: forgotpasswordJ2iIcuCsApMeta?.path ?? "/es/user/forgotpassword",
    meta: forgotpasswordJ2iIcuCsApMeta || {},
    alias: forgotpasswordJ2iIcuCsApMeta?.alias || [],
    redirect: forgotpasswordJ2iIcuCsApMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: forgotpasswordJ2iIcuCsApMeta?.name ?? "user-forgotpassword___es-XL",
    path: forgotpasswordJ2iIcuCsApMeta?.path ?? "/es-XL/user/forgotpassword",
    meta: forgotpasswordJ2iIcuCsApMeta || {},
    alias: forgotpasswordJ2iIcuCsApMeta?.alias || [],
    redirect: forgotpasswordJ2iIcuCsApMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: forgotpasswordJ2iIcuCsApMeta?.name ?? "user-forgotpassword___pt-BR",
    path: forgotpasswordJ2iIcuCsApMeta?.path ?? "/pt-BR/user/forgotpassword",
    meta: forgotpasswordJ2iIcuCsApMeta || {},
    alias: forgotpasswordJ2iIcuCsApMeta?.alias || [],
    redirect: forgotpasswordJ2iIcuCsApMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: profileP8xjiyB0BqMeta?.name ?? "user-profile___en",
    path: profileP8xjiyB0BqMeta?.path ?? "/user/profile",
    meta: profileP8xjiyB0BqMeta || {},
    alias: profileP8xjiyB0BqMeta?.alias || [],
    redirect: profileP8xjiyB0BqMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileP8xjiyB0BqMeta?.name ?? "user-profile___fr",
    path: profileP8xjiyB0BqMeta?.path ?? "/fr/user/profile",
    meta: profileP8xjiyB0BqMeta || {},
    alias: profileP8xjiyB0BqMeta?.alias || [],
    redirect: profileP8xjiyB0BqMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileP8xjiyB0BqMeta?.name ?? "user-profile___it",
    path: profileP8xjiyB0BqMeta?.path ?? "/it/user/profile",
    meta: profileP8xjiyB0BqMeta || {},
    alias: profileP8xjiyB0BqMeta?.alias || [],
    redirect: profileP8xjiyB0BqMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileP8xjiyB0BqMeta?.name ?? "user-profile___de",
    path: profileP8xjiyB0BqMeta?.path ?? "/de/user/profile",
    meta: profileP8xjiyB0BqMeta || {},
    alias: profileP8xjiyB0BqMeta?.alias || [],
    redirect: profileP8xjiyB0BqMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileP8xjiyB0BqMeta?.name ?? "user-profile___es",
    path: profileP8xjiyB0BqMeta?.path ?? "/es/user/profile",
    meta: profileP8xjiyB0BqMeta || {},
    alias: profileP8xjiyB0BqMeta?.alias || [],
    redirect: profileP8xjiyB0BqMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileP8xjiyB0BqMeta?.name ?? "user-profile___es-XL",
    path: profileP8xjiyB0BqMeta?.path ?? "/es-XL/user/profile",
    meta: profileP8xjiyB0BqMeta || {},
    alias: profileP8xjiyB0BqMeta?.alias || [],
    redirect: profileP8xjiyB0BqMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: profileP8xjiyB0BqMeta?.name ?? "user-profile___pt-BR",
    path: profileP8xjiyB0BqMeta?.path ?? "/pt-BR/user/profile",
    meta: profileP8xjiyB0BqMeta || {},
    alias: profileP8xjiyB0BqMeta?.alias || [],
    redirect: profileP8xjiyB0BqMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: verifyAwZaSzCmO3Meta?.name ?? "user-verify___en",
    path: verifyAwZaSzCmO3Meta?.path ?? "/user/verify",
    meta: verifyAwZaSzCmO3Meta || {},
    alias: verifyAwZaSzCmO3Meta?.alias || [],
    redirect: verifyAwZaSzCmO3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyAwZaSzCmO3Meta?.name ?? "user-verify___fr",
    path: verifyAwZaSzCmO3Meta?.path ?? "/fr/user/verify",
    meta: verifyAwZaSzCmO3Meta || {},
    alias: verifyAwZaSzCmO3Meta?.alias || [],
    redirect: verifyAwZaSzCmO3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyAwZaSzCmO3Meta?.name ?? "user-verify___it",
    path: verifyAwZaSzCmO3Meta?.path ?? "/it/user/verify",
    meta: verifyAwZaSzCmO3Meta || {},
    alias: verifyAwZaSzCmO3Meta?.alias || [],
    redirect: verifyAwZaSzCmO3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyAwZaSzCmO3Meta?.name ?? "user-verify___de",
    path: verifyAwZaSzCmO3Meta?.path ?? "/de/user/verify",
    meta: verifyAwZaSzCmO3Meta || {},
    alias: verifyAwZaSzCmO3Meta?.alias || [],
    redirect: verifyAwZaSzCmO3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyAwZaSzCmO3Meta?.name ?? "user-verify___es",
    path: verifyAwZaSzCmO3Meta?.path ?? "/es/user/verify",
    meta: verifyAwZaSzCmO3Meta || {},
    alias: verifyAwZaSzCmO3Meta?.alias || [],
    redirect: verifyAwZaSzCmO3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyAwZaSzCmO3Meta?.name ?? "user-verify___es-XL",
    path: verifyAwZaSzCmO3Meta?.path ?? "/es-XL/user/verify",
    meta: verifyAwZaSzCmO3Meta || {},
    alias: verifyAwZaSzCmO3Meta?.alias || [],
    redirect: verifyAwZaSzCmO3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyAwZaSzCmO3Meta?.name ?? "user-verify___pt-BR",
    path: verifyAwZaSzCmO3Meta?.path ?? "/pt-BR/user/verify",
    meta: verifyAwZaSzCmO3Meta || {},
    alias: verifyAwZaSzCmO3Meta?.alias || [],
    redirect: verifyAwZaSzCmO3Meta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/user/verify.vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-packs-packslug-gameslug___en",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/games/:packslug/:gameslug",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-packs-packslug-gameslug___fr",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/fr/games/:packslug/:gameslug",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-packs-packslug-gameslug___it",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/it/games/:packslug/:gameslug",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-packs-packslug-gameslug___de",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/de/games/:packslug/:gameslug",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-packs-packslug-gameslug___es",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/es/games/:packslug/:gameslug",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-packs-packslug-gameslug___es-XL",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/es-XL/games/:packslug/:gameslug",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: _91game_45slug_93yFRhSmvz1iMeta?.name ?? "games-packs-packslug-gameslug___pt-BR",
    path: _91game_45slug_93yFRhSmvz1iMeta?.path ?? "/pt-BR/games/:packslug/:gameslug",
    meta: _91game_45slug_93yFRhSmvz1iMeta || {},
    alias: _91game_45slug_93yFRhSmvz1iMeta?.alias || [],
    redirect: _91game_45slug_93yFRhSmvz1iMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/games/[game-slug].vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift-locale___en",
    path: giftmbcvizTL7nMeta?.path ?? "/gift:locale",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift-locale___fr",
    path: giftmbcvizTL7nMeta?.path ?? "/fr/gift:locale",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift-locale___it",
    path: giftmbcvizTL7nMeta?.path ?? "/it/gift:locale",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift-locale___de",
    path: giftmbcvizTL7nMeta?.path ?? "/de/gift:locale",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift-locale___es",
    path: giftmbcvizTL7nMeta?.path ?? "/es/gift:locale",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift-locale___es-XL",
    path: giftmbcvizTL7nMeta?.path ?? "/es-XL/gift:locale",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmbcvizTL7nMeta?.name ?? "gift-locale___pt-BR",
    path: giftmbcvizTL7nMeta?.path ?? "/pt-BR/gift:locale",
    meta: giftmbcvizTL7nMeta || {},
    alias: giftmbcvizTL7nMeta?.alias || [],
    redirect: giftmbcvizTL7nMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker-locale___en",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/the-jackbox-megapicker:locale",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker-locale___fr",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/fr/the-jackbox-megapicker:locale",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker-locale___it",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/it/the-jackbox-megapicker:locale",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker-locale___de",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/de/the-jackbox-megapicker:locale",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker-locale___es",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/es/the-jackbox-megapicker:locale",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker-locale___es-XL",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/es-XL/the-jackbox-megapicker:locale",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: the_45jackbox_45megapickerz256JihBVPMeta?.name ?? "the-jackbox-megapicker-locale___pt-BR",
    path: the_45jackbox_45megapickerz256JihBVPMeta?.path ?? "/pt-BR/the-jackbox-megapicker:locale",
    meta: the_45jackbox_45megapickerz256JihBVPMeta || {},
    alias: the_45jackbox_45megapickerz256JihBVPMeta?.alias || [],
    redirect: the_45jackbox_45megapickerz256JihBVPMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/the-jackbox-megapicker.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login-step___en",
    path: loginpJbw2IDcKUMeta?.path ?? "/login/:step",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login-step___fr",
    path: loginpJbw2IDcKUMeta?.path ?? "/fr/login/:step",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login-step___it",
    path: loginpJbw2IDcKUMeta?.path ?? "/it/login/:step",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login-step___de",
    path: loginpJbw2IDcKUMeta?.path ?? "/de/login/:step",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login-step___es",
    path: loginpJbw2IDcKUMeta?.path ?? "/es/login/:step",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login-step___es-XL",
    path: loginpJbw2IDcKUMeta?.path ?? "/es-XL/login/:step",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginpJbw2IDcKUMeta?.name ?? "login-step___pt-BR",
    path: loginpJbw2IDcKUMeta?.path ?? "/pt-BR/login/:step",
    meta: loginpJbw2IDcKUMeta || {},
    alias: loginpJbw2IDcKUMeta?.alias || [],
    redirect: loginpJbw2IDcKUMeta?.redirect,
    component: () => import("/home/runner/work/jbg-web/jbg-web/src/pages/login.vue").then(m => m.default || m)
  },
  {
    path: "/support",
    name: "support"
  }
]